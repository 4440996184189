@import 'src/shared/variables.scss';


.sidenav {
  height: 100%;
  width: $sidebar-width;
  //border-right: $sidebar-border-width solid $sidebar-border-color;
  position: fixed;
  z-index: 1070;
  top: 0;
  left: 0;
  background-color: $background-black;
  overflow-x: hidden;
  transition: all ease .5s ;
  &__items{
    margin-top: 2vh;
    &__item {
      height: 70px;
      margin-left: 0;
      padding-left: 20px;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: white;
    
      svg {
      margin-right: 10%;
    }
    
      p {
        font-size: 18px;
      }
    }
  }
  /* The navigation menu links */
}

.show {
  z-index: 1071;
  top: 0px;
  left: 0px;
}

.hide {
  z-index: 1071;
  top: 0px;
  left: 0px;
}

@media screen and (min-width: 968px) {
  .sidenav {
    display: none;
  }
}

@media screen and (max-width: 968px) {
  div .show {
    left: 0px;
  }
  
  div .hide {
    left: -$sidebar-width;
  }
}

@media screen and (max-width: 480px) {

}

@media screen and (max-width: 320px) {

}