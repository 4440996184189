.make-call {
  display: flex;
  position: fixed;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  right: 30px;
  bottom: 30px;
}
