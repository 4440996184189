/*********************************************************************************

	Template Name: Paneles Aislados
	Description: SUMINISTRO DE PANELES CON AISLAMIENTO EN TODA LA REPÚBLICA
	Version: 1.0

	Note: This is style css.

**********************************************************************************/


/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Basic Styles
	|	|
	|	|___ Reset Styles
	|	|___ Typography Styles
	|	|___ Utilities
	|	|___ Animations	
	|
	|
	|___Elements Styles
	|	|___ Button Styles
	|	|___ Section Title
	|	|___ Social Icons Styles
	|	|___ Breadcrumb Area
	|	|___ Progress Bar
	|	|___ Accordion Styles
	|	|___ Pagination Styles
	|	|___ Call to action
	|
	|
	|___Blocks Styles
	|	|___ Header Styles 
	|	|___ Navigation Styles 
	|	|___ Banner Styles
	|	|___ About Styles
	|	|___ Service styels
	|	|___ Projects Styles
	|	|___ Brand Logos
	|	|___ Work Process
	|	|___ Blog Item Styles
	|	|___ Widget Styles
	|	|___ Team Member
	|	|___ Funfact Area
	|	|___ Testimonial Styles
	|	|___ Pricebox Styles
	|	|___ Contact Styles
	|	|___ Pg Blog Item Styles
	|	|___ 404 Page Styles
	|	|___ Comment Styles
	|	|___ Slider Elements
	|	|___ Footer Styles
	|	|___ Boxed Layout Styles
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/


/*******************
Basic Styles
********************/


/**
* Reset Styles
*/

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

html {
    font-size: 100%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
}

body {
    color: #141412;
    line-height: 1.5;
    margin: 0;
}

a {
    color: #ca3c08;
    text-decoration: none;
}

a:visited {
    color: #ac0404;
}

a:focus {
    outline: thin dotted;
}

a:active,
a:hover {
    color: #ea9629;
    outline: 0;
}

a:hover {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
    font-family: Bitter, Georgia, serif;
    line-height: 1.3;
}

h1 {
    font-size: 48px;
    margin: 33px 0;
}

h2 {
    font-size: 30px;
    margin: 25px 0;
}

h3 {
    font-size: 22px;
    margin: 22px 0;
}

h4 {
    font-size: 20px;
    margin: 25px 0;
}

h5 {
    font-size: 18px;
    margin: 30px 0;
}

h6 {
    font-size: 16px;
    margin: 36px 0;
}

address {
    font-style: italic;
    margin: 0 0 24px;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

mark {
    background: #ff0;
    color: #000;
}

p {
    margin: 0 0 24px;
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 14px;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre {
    background: #f5f5f5;
    color: #666;
    font-family: monospace;
    font-size: 14px;
    margin: 20px 0;
    overflow: auto;
    padding: 20px;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

blockquote,
q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

blockquote {
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    margin: 24px 40px;
}

blockquote blockquote {
    margin-right: 0;
}

blockquote cite,
blockquote small {
    font-size: 14px;
    font-weight: normal;
    text-transform: uppercase;
}

blockquote em,
blockquote i {
    font-style: normal;
    font-weight: 300;
}

blockquote strong,
blockquote b {
    font-weight: 400;
}

small {
    font-size: smaller;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

dl {
    margin: 0 20px;
}

dt {
    font-weight: bold;
}

dd {
    margin: 0 0 20px;
}

menu,
ol,
ul {
    margin: 16px 0;
    padding: 0 0 0 40px;
}

ul {
    list-style-type: square;
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

li>ul,
li>ol {
    margin: 0;
}

img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    min-width: inherit;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
}

button,
input {
    line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    cursor: pointer;
}

button[disabled],
input[disabled] {
    cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    appearance: textfield;
    padding-right: 2px;
    /* Don't cut off the webkit search cancel button */
    width: 270px;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    -o-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-bottom: 1px solid #ededed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
    line-height: 2;
    margin: 0 0 20px;
    width: 100%;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

caption {
    font-size: 16px;
    margin: 20px 0;
}

th {
    font-weight: bold;
    text-transform: uppercase;
}

td {
    border-top: 1px solid #ededed;
    padding: 6px 10px 6px 0;
}

del {
    color: #333;
}

ins {
    background: #fff9c0;
    text-decoration: none;
}

hr {
    background-size: 4px 4px;
    border: 0;
    height: 1px;
    margin: 0 0 24px;
}


/* Placeholder text color -- selectors need to be separate to work. */

::-webkit-input-placeholder {
    color: #7d7b6d;
}

:-moz-placeholder {
    color: #7d7b6d;
}

::-moz-placeholder {
    color: #7d7b6d;
}

:-ms-input-placeholder {
    color: #7d7b6d;
}


/**
* Typography Styles
*/

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

html {
    overflow: hidden;
    overflow-y: auto;
}

body {
    overflow: hidden;
    font-size: 14px;
    line-height: 24px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Lato", sans-serif;
    color: #003366;
    font-weight: 400;
}

::-webkit-input-placeholder {
    color: #003366;
}

:-moz-placeholder {
    color: #003366;
}

::-moz-placeholder {
    color: #003366;
}

:-ms-input-placeholder {
    color: #003366;
}

*::-moz-selection {
    background: #ffb300;
    color: #ffffff;
}

*::selection {
    background: #ffb300;
    color: #ffffff;
}

*::-moz-selection {
    background: #ffb300;
    color: #ffffff;
}

a {
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
    margin: 0;
    margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Raleway", sans-serif;
    line-height: 1.3;
    color: #2b2b35;
}

h1,
.h1 {
    font-size: 48px;
}

h2,
.h2 {
    font-size: 36px;
}

h3,
.h3 {
    font-size: 30px;
}

h4,
.h4 {
    font-size: 20px;
}

h5,
.h5 {
    font-size: 18px;
}

h6,
.h6 {
    font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    h1,
    .h1 {
        font-size: 42px;
    }
    h2,
    .h2 {
        font-size: 30px;
    }
    h3,
    .h3 {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    h1,
    .h1 {
        font-size: 36px;
    }
    h2,
    .h2 {
        font-size: 28px;
    }
    h3,
    .h3 {
        font-size: 22px;
    }
    h4,
    .h4 {
        font-size: 18px;
    }
    h5,
    .h5 {
        font-size: 16px;
    }
}

@media only screen and (max-width: 575px) {
    h1,
    .h1 {
        font-size: 36px;
    }
    h2,
    .h2 {
        font-size: 28px;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
    font-weight: 700;
}

h4,
.h4,
h5,
.h5 {
    font-weight: 600;
}

h6,
.h6 {
    font-weight: 500;
}

a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a:visited {
    color: inherit;
}

input,
button,
select,
textarea {
    background: transparent;
    border: 1px solid #3d3e43;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

input:focus,
input:active,
button:focus,
button:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
    outline: none;
}

input,
select,
textarea {
    width: 100%;
}

input,
select {
    height: 45px;
    padding: 0 20px;
}

blockquote {
    font-size: 18px;
    color: #444444;
    line-height: 28px;
    font-style: italic;
    font-weight: 400;
    border-left: 4px solid #ffb300;
    padding-left: 15px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    text-align: left;
}


/**
* Utilities
*/

.hidden {
    display: none;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}

.wrapper {
    position: relative;
}


/* Define Colors */

.color-theme {
    color: #ffb300;
}

.color-light {
    color: #999999;
}

.color-dark {
    color: #333333;
}

.color-black {
    color: #000000;
}

.color-white {
    color: #ffffff;
}


/* Background Color */

.bg-theme {
    background: #ffb300 !important;
}

.bg-light {
    background: #999999 !important;
}

.bg-grey {
    background: #f5f5f5 !important;
}

.bg-dark {
    background: #2b2b35 !important;
}

.bg-dark-light {
    background: #363636 !important;
}

.bg-black {
    background: #000000 !important;
}

.bg-white {
    background: #ffffff !important;
}


/* Youtube Video Background */

.background-video-holder {
    position: relative;
}

.youtube-video-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0 !important;
    left: 0;
    z-index: -1;
}

.youtube-bg {
    display: block;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}


/* Background Image */

.bg-image-1 {
    background-image: url(images/bg/bg-image-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-2 {
    background-image: url(images/bg/bg-image-2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-3 {
    background-image: url(images/bg/bg-image-3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-4 {
    background-image: url(images/bg/bg-image-4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-5 {
    background-image: url(images/bg/bg-image-5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-6 {
    background-image: url(images/bg/bg-image-6.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-7 {
    background-image: url(images/bg/bg-image-7.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-8 {
    background-image: url(images/bg/bg-image-8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-9 {
    background-image: url(images/bg/bg-image-9.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-10 {
    background-image: url(images/bg/bg-image-10.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-11 {
    background-image: url(images/bg/bg-image-11.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-12 {
    background-image: url(images/bg/bg-image-12.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-13 {
    background-image: url(images/bg/bg-image-13.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-14 {
    background-image: url(images/bg/bg-image-14.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-15 {
    background-image: url(images/bg/bg-image-15.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-16 {
    background-image: url(images/bg/bg-image-16.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-17 {
    background-image: url(images/bg/bg-image-17.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-18 {
    background-image: url(images/bg/bg-image-18.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-19 {
    background-image: url(images/bg/bg-image-19.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-image-20 {
    background-image: url(images/bg/bg-image-20.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


/* Text specialized */

.text-italic {
    font-style: italic;
}

.text-normal {
    font-style: normal;
}

.text-underline {
    font-style: underline;
}


/* Font specialized */

.body-font {
    font-family: "Lato", sans-serif;
}

.heading-font {
    font-family: "Raleway", sans-serif;
}


/* Height and width */

.fullscreen {
    min-height: 100vh;
    width: 100%;
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.w-33 {
    width: 33.33%;
}

.w-25 {
    width: 25%;
}

.w-10 {
    width: 10%;
}

.h-100 {
    height: 100%;
}

.h-75 {
    height: 75%;
}

.h-50 {
    height: 50%;
}

.h-25 {
    height: 25%;
}

.h-33 {
    height: 33.33%;
}

.h-10 {
    height: 10%;
}


/* Section padding */

.section-padding-xlg {
    padding: 130px 0;
}

.section-padding-lg {
    padding: 120px 0;
}

.section-padding-md {
    padding: 100px 0;
}

.section-padding-sm {
    padding: 80px 0;
}

.section-padding-xs {
    padding: 65px 0;
}

.section-padding-top-xlg {
    padding-top: 130px;
}

.section-padding-top-lg {
    padding-top: 120px;
}

.section-padding-top-md {
    padding-top: 100px;
}

.section-padding-top-sm {
    padding-top: 80px;
}

.section-padding-top-xs {
    padding-top: 65px;
}

.section-padding-bottom-xlg {
    padding-bottom: 130px;
}

.section-padding-bottom-lg {
    padding-bottom: 120px;
}

.section-padding-bottom-md {
    padding-bottom: 100px;
}

.section-padding-bottom-sm {
    padding-bottom: 80px;
}

.section-padding-bottom-xs {
    padding-bottom: 65px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-padding-xlg {
        padding: 90px 0;
    }
    .section-padding-lg {
        padding: 80px 0;
    }
    .section-padding-top-xlg {
        padding-top: 90px;
    }
    .section-padding-top-lg {
        padding-top: 80px;
    }
    .section-padding-bottom-xlg {
        padding-bottom: 90px;
    }
    .section-padding-bottom-lg {
        padding-bottom: 80px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-padding-xlg {
        padding: 110px 0;
    }
    .section-padding-lg {
        padding: 100px 0;
    }
    .section-padding-md {
        padding: 80px 0;
    }
    .section-padding-sm {
        padding: 60px 0;
    }
    .section-padding-top-xlg {
        padding-top: 110px;
    }
    .section-padding-top-lg {
        padding-top: 100px;
    }
    .section-padding-top-md {
        padding-top: 80px;
    }
    .section-padding-top-sm {
        padding-top: 60px;
    }
    .section-padding-bottom-xlg {
        padding-bottom: 110px;
    }
    .section-padding-bottom-lg {
        padding-bottom: 100px;
    }
    .section-padding-bottom-md {
        padding-bottom: 80px;
    }
    .section-padding-bottom-sm {
        padding-bottom: 60px;
    }
}

@media only screen and (max-width: 767px) {
    .section-padding-xlg {
        padding: 100px 0;
    }
    .section-padding-lg {
        padding: 90px 0;
    }
    .section-padding-md {
        padding: 80px 0;
    }
    .section-padding-sm {
        padding: 60px 0;
    }
    .section-padding-top-xlg {
        padding-top: 100px;
    }
    .section-padding-top-lg {
        padding-top: 90px;
    }
    .section-padding-top-md {
        padding-top: 80px;
    }
    .section-padding-top-sm {
        padding-top: 60px;
    }
    .section-padding-bottom-xlg {
        padding-bottom: 100px;
    }
    .section-padding-bottom-lg {
        padding-bottom: 90px;
    }
    .section-padding-bottom-md {
        padding-bottom: 80px;
    }
    .section-padding-bottom-sm {
        padding-bottom: 60px;
    }
}


/* Border */

.cr-border {
    border-color: #a8a8a8;
    border-style: solid;
    border-width: 1px;
}

.cr-border-top {
    border-color: #a8a8a8;
    border-style: solid;
    border-width: 1px 0 0 0;
}

.cr-border-right {
    border-color: #a8a8a8;
    border-style: solid;
    border-width: 0 1px 0 0;
}

.cr-border-bottom {
    border-color: #a8a8a8;
    border-style: solid;
    border-width: 0 0 1px 0;
}

.cr-border-left {
    border-color: #a8a8a8;
    border-style: solid;
    border-width: 0 0 0 1px;
}


/* Overlay styles */

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
    position: relative;
}

[data-overlay]>div,
[data-overlay]>*,
[data-black-overlay]>div,
[data-black-overlay]>*,
[data-white-overlay]>div,
[data-white-overlay]>* {
    position: relative;
    z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
}

[data-overlay]:before {
    background-color: #ffb300;
}

[data-black-overlay]:before {
    background-color: #000000;
}

[data-white-overlay]:before {
    background-color: #f1f1f1;
}

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
    opacity: 0.1;
}

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
    opacity: 0.2;
}

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
    opacity: 0.3;
}

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
    opacity: 0.4;
}

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
    opacity: 0.5;
}

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
    opacity: 0.6;
}

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
    opacity: 0.7;
}

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
    opacity: 0.8;
}

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
    opacity: 0.9;
}

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
    opacity: 1;
}

.slick-slide:active,
.slick-slide:focus {
    outline: none;
}

.slick-slide.slick-current:active,
.slick-slide.slick-current:focus,
.slick-slide.slick-active:active,
.slick-slide.slick-active:focus {
    outline: none;
}

.bg-theme {
    color: #ffffff;
}

.bg-theme h1,
.bg-theme h2,
.bg-theme h3,
.bg-theme h4,
.bg-theme h5,
.bg-theme h6,
.bg-theme p {
    color: #ffffff;
}

.jarallax {
    position: relative;
    z-index: 0;
}

.jarallax>.jarallax-img {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

#scrollUp {
    right: 30px;
    bottom: 30px;
    height: 45px;
    width: 45px;
    line-height: 41px;
    text-align: center;
    background: #ffb300;
    color: #ffffff;
    font-size: 22px;
    border-radius: 100%;
    border: 1px solid #ffb300;
    -webkit-animation: crSlideInRight 0.8s ease-in-out 1 both;
    animation: crSlideInRight 0.8s ease-in-out 1 both;
    z-index: 1000 !important;
}

@media only screen and (max-width: 767px) {
    #scrollUp {
        display: none !important;
    }
}

@media only screen and (min-width: 1600px) {
    .row .col-2-of-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
    .row .col-4-of-10 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
    }
}

.mt-30 {
    margin-top: 30px;
}

.mb-30 {
    margin-bottom: 30px;
}

.form-message {
    margin-bottom: 0;
    text-align: center;
}

.form-message.error {
    color: #dd3327;
    margin-top: 20px;
}

.form-message.success {
    margin-top: 20px;
    color: #1f862d;
}

.jarallax {
    position: relative;
    z-index: 0;
}

.jarallax>.jarallax-img {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.tab-content {
    width: 100%;
}

.tab-content .tab-pane {
    display: block;
    height: 0;
    max-width: 100%;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
}

.tab-content .tab-pane.active {
    height: auto;
    visibility: visible;
    opacity: 1;
    overflow: visible;
}

@media only screen and (max-width: 767px) {
    .container {
        max-width: 515px;
    }
}

@media only screen and (max-width: 575px) {
    .container {
        max-width: 420px;
    }
}


/**
* Animations
*/

@-webkit-keyframes crZoomInOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes crZoomInOut {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes crZoomInOutInvisible {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

@keyframes crZoomInOutInvisible {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1.6);
        transform: scale(1.6);
        opacity: 0;
    }
}

@-webkit-keyframes crFadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes crFadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 30px, 0);
        transform: translate3d(0, 30px, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@-webkit-keyframes crSlideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes crSlideInDown {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes crBounceIn {
    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes crBounceIn {
    20%,
    40%,
    60%,
    80%,
    from,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes crSlideInRight {
    0% {
        -webkit-transform: translateX(150px);
        transform: translateX(150px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes crSlideInRight {
    0% {
        -webkit-transform: translateX(150px);
        transform: translateX(150px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@-webkit-keyframes carInOutAnimation {
    0% {
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
    }
    18% {
        -webkit-transform: translateX(-5%);
        transform: translateX(-5%);
    }
    20% {
        -webkit-transform: translateX(5%);
        transform: translateX(5%);
    }
    22% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    78% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    80% {
        -webkit-transform: translateX(-5%);
        transform: translateX(-5%);
    }
    82% {
        -webkit-transform: translateX(5%);
        transform: translateX(5%);
    }
    100% {
        -webkit-transform: translateX(-200%);
        transform: translateX(-200%);
    }
}

@keyframes carInOutAnimation {
    0% {
        -webkit-transform: translateX(200%);
        transform: translateX(200%);
    }
    18% {
        -webkit-transform: translateX(-5%);
        transform: translateX(-5%);
    }
    20% {
        -webkit-transform: translateX(5%);
        transform: translateX(5%);
    }
    22% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    78% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
    80% {
        -webkit-transform: translateX(-5%);
        transform: translateX(-5%);
    }
    82% {
        -webkit-transform: translateX(5%);
        transform: translateX(5%);
    }
    100% {
        -webkit-transform: translateX(-200%);
        transform: translateX(-200%);
    }
}

@-webkit-keyframes carSlideShow {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    15% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    18% {
        -webkit-transform: scale(1.3) translateX(-10%);
        transform: scale(1.3) translateX(-10%);
    }
    82% {
        -webkit-transform: scale(1.3) translateX(10%);
        transform: scale(1.3) translateX(10%);
    }
    85% {
        -webkit-transform: scale(1.3) translateX(0);
        transform: scale(1.3) translateX(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes carSlideShow {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    15% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    18% {
        -webkit-transform: scale(1.3) translateX(-10%);
        transform: scale(1.3) translateX(-10%);
    }
    82% {
        -webkit-transform: scale(1.3) translateX(10%);
        transform: scale(1.3) translateX(10%);
    }
    85% {
        -webkit-transform: scale(1.3) translateX(0);
        transform: scale(1.3) translateX(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


/*******************
Elements Styles
********************/


/**
* Button Styles 
*/

.cr-btn,
a.cr-btn,
button.cr-btn {
    display: inline-block;
    background: #ffb300;
    font-size: 14px;
    border-width: 2px;
    border-color: #ffb300;
    border-style: solid;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    overflow: hidden;
    position: relative;
    line-height: 1.3;
    padding: 13px 25px;
    vertical-align: middle;
    border-radius: 3px;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    /* Size Variations */
    /* Color Variations */
}

.cr-btn i,
a.cr-btn i,
button.cr-btn i {
    padding-right: 5px;
    line-height: 1;
    font-size: 18px;
    vertical-align: bottom;
}

.cr-btn:before,
.cr-btn:after,
a.cr-btn:before,
a.cr-btn:after,
button.cr-btn:before,
button.cr-btn:after {
    content: "";
    position: absolute;
    height: 0;
    width: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.cr-btn:before,
a.cr-btn:before,
button.cr-btn:before {
    left: 0;
    top: 0;
}

.cr-btn:after,
a.cr-btn:after,
button.cr-btn:after {
    bottom: 0;
    left: 0;
}

.cr-btn span,
a.cr-btn span,
button.cr-btn span {
    position: relative;
    z-index: 2;
}

.cr-btn:hover,
a.cr-btn:hover,
button.cr-btn:hover {
    background: #ffb300;
    border-color: #ffb300;
    color: #ffffff;
    -webkit-transform: translateY(-4px);
    -ms-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.cr-btn:hover::before,
a.cr-btn:hover::before,
button.cr-btn:hover::before {
    height: 100%;
}

.cr-btn:hover:after,
a.cr-btn:hover:after,
button.cr-btn:hover:after {
    height: 100%;
}

.cr-btn:active,
a.cr-btn:active,
button.cr-btn:active {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
}

.cr-btn.cr-btn-lg,
a.cr-btn.cr-btn-lg,
button.cr-btn.cr-btn-lg {
    padding: 20px 40px;
    font-size: 18px;
    letter-spacing: 0;
}

.cr-btn.cr-btn-xs,
a.cr-btn.cr-btn-xs,
button.cr-btn.cr-btn-xs {
    padding: 7px 26px;
}

.cr-btn.cr-btn-theme-black,
a.cr-btn.cr-btn-theme-black,
button.cr-btn.cr-btn-theme-black {
    color: #2b2b35;
}

.cr-btn.cr-btn-yellow,
a.cr-btn.cr-btn-yellow,
button.cr-btn.cr-btn-yellow {
    background: #ffb300;
    border-color: #ffb300;
}

.cr-btn.cr-btn-blue,
a.cr-btn.cr-btn-blue,
button.cr-btn.cr-btn-blue {
    background: #ffb300;
    border-color: #ffb300;
}

.cr-btn.cr-btn-green,
a.cr-btn.cr-btn-green,
button.cr-btn.cr-btn-green {
    background: #ffb300;
    border-color: #ffb300;
}

.cr-btn.cr-btn-white,
a.cr-btn.cr-btn-white,
button.cr-btn.cr-btn-white {
    background: #ffffff;
    border-color: #ffffff;
    color: #2b2b35;
}

.cr-btn.cr-btn-black,
a.cr-btn.cr-btn-black,
button.cr-btn.cr-btn-black {
    background: #3e3e3e;
    border-color: #3e3e3e;
    color: #ffffff;
}

.cr-btn.cr-btn-transparent,
a.cr-btn.cr-btn-transparent,
button.cr-btn.cr-btn-transparent {
    background: transparent;
    border-width: 2px;
    color: #ffb300;
}

.cr-btn.cr-btn-transparent:hover,
a.cr-btn.cr-btn-transparent:hover,
button.cr-btn.cr-btn-transparent:hover {
    background: #ffb300;
    color: #ffffff;
}

.cr-btn.cr-btn-transparent.cr-btn-white,
a.cr-btn.cr-btn-transparent.cr-btn-white,
button.cr-btn.cr-btn-transparent.cr-btn-white {
    color: #ffffff;
}

.cr-btn.cr-btn-transparent.cr-btn-white:hover,
a.cr-btn.cr-btn-transparent.cr-btn-white:hover,
button.cr-btn.cr-btn-transparent.cr-btn-white:hover {
    background: #ffffff;
    color: #2b2b35;
}

.cr-btn.cr-btn-transparent.cr-btn-black,
a.cr-btn.cr-btn-transparent.cr-btn-black,
button.cr-btn.cr-btn-transparent.cr-btn-black {
    color: #2b2b35;
}

.cr-btn.cr-btn-transparent.cr-btn-black:hover,
a.cr-btn.cr-btn-transparent.cr-btn-black:hover,
button.cr-btn.cr-btn-transparent.cr-btn-black:hover {
    background: #2b2b35;
    color: #ffffff;
}

.cr-btn.cr-loadmore-btn,
a.cr-btn.cr-loadmore-btn,
button.cr-btn.cr-loadmore-btn {
    padding: 12px 25px;
    letter-spacing: 1.8px;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 12px;
}

.cr-btn.cr-loadmore-btn:hover,
a.cr-btn.cr-loadmore-btn:hover,
button.cr-btn.cr-loadmore-btn:hover {
    background: #ffb300;
    border-color: #ffb300;
}

.button-group {
    margin-left: -5px;
    margin-right: -5px;
}

.button-group a.cr-btn {
    margin: 0 5px;
    margin-top: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cr-btn.cr-btn-lg,
    a.cr-btn.cr-btn-lg,
    button.cr-btn.cr-btn-lg {
        padding: 15px 30px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px) {
    .cr-btn.cr-btn-lg,
    a.cr-btn.cr-btn-lg,
    button.cr-btn.cr-btn-lg {
        padding: 15px 30px;
        font-size: 16px;
    }
}


/**
* Section Title
*/

.section-title {
    padding-bottom: 45px;
}

.section-title h2 {
    font-size: 30px;
    font-weight: 800;
    color: #2b2b35;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.section-title h2::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 0;
    height: 3px;
    width: 46px;
    background: #2b2b35;
    display: inline-block;
}

.section-title h3 {
    font-size: 28px;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    letter-spacing: 0.5px;
    padding-left: 25px;
    position: relative;
    z-index: 1;
    padding-top: 20px;
}

.section-title h3::before {
    content: "";
    height: 40px;
    width: 40px;
    background: #ffb300;
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
}

.section-title p {
    margin-top: 25px;
    font-weight: 300;
    font-style: italic;
    font-size: 16px;
    color: #666666;
}

.section-title.text-center h2::after {
    left: 50%;
    margin-left: -23px;
}

.section-title.text-right h2::after {
    left: auto;
    right: 0;
    margin-left: 0;
}

.section-title.section-title-2 {
    padding-bottom: 35px;
}

.section-title.section-title-2 h2 {
    font-size: 30px;
    font-weight: 800;
    padding-bottom: 0;
}

.section-title.section-title-2 h2::after {
    display: none;
}

.section-title.section-title-2 p {
    margin-top: 20px;
}

.small-title {
    font-weight: 900;
    margin-top: -3px;
    margin-bottom: 30px;
}

.bg-dark .section-title h2,
.bg-dark .section-title h3,
.bg-dark .section-title p {
    color: #ffffff;
}

.bg-dark .small-title {
    color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .section-title {
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .section-title {
        padding-bottom: 30px;
    }
    .section-title h2 {
        font-size: 26px;
    }
    .section-title h3 {
        font-size: 24px;
    }
    .section-title.section-title-2 h2 {
        font-size: 26px;
    }
}


/**
* Social Icons Styles
*/

.social-icons ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 0;
    line-height: 1;
}

.social-icons ul li {
    display: inline-block;
    margin: 0 7px;
    list-style: none;
    font-size: 16px;
}

.social-icons ul li:first-child {
    margin-left: 0;
}

.social-icons ul li:last-child {
    margin-right: 0;
}

.social-icons ul li a {
    color: #9d9d9e;
}

.social-icons ul li a:hover {
    color: #ffb300;
}

.social-icons ul li.facebook:hover a {
    color: #3B5998;
}

.social-icons ul li.twitter:hover a {
    color: #00ACEE;
}

.social-icons ul li.google-plus:hover a {
    color: #DD4B39;
}

.social-icons ul li.pinterest:hover a {
    color: #C8232C;
}

.social-icons ul li.linkedin:hover a {
    color: #0E76A8;
}

.social-icons ul li.flickr:hover a {
    color: #FF0084;
}

.social-icons ul li.dribbble:hover a {
    color: #EA4C89;
}

.social-icons ul li.google:hover a {
    color: #DD4B39;
}

.social-icons ul li.skype:hover a {
    color: #00AFF0;
}

.social-icons ul li.youtube:hover a {
    color: #C4302B;
}

.social-icons ul li.vimeo:hover a {
    color: #86C9EF;
}

.social-icons ul li.yahoo:hover a {
    color: #720E9E;
}

.social-icons ul li.paypal:hover a {
    color: #00588B;
}

.social-icons ul li.instagram:hover a {
    color: #D82E86;
}

.social-icons.social-icons-lg ul li {
    font-size: 18px;
}

.social-icons-rounded ul li {
    display: inline-block;
    margin: 0 7px;
    list-style: none;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 100px;
}

.social-icons-rounded ul li:first-child {
    margin-left: 0;
}

.social-icons-rounded ul li:last-child {
    margin-right: 0;
}

.social-icons-rounded ul li a {
    color: #666666;
    height: 35px;
    width: 35px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 35px;
    color: #ffffff;
    border-radius: 100px;
    overflow: hidden;
    font-size: 14px;
}

.social-icons-rounded ul li.facebook a {
    background: #3B5998;
}

.social-icons-rounded ul li.facebook:hover a {
    color: #3B5998;
    background: #ffffff;
}

.social-icons-rounded ul li.twitter a {
    background: #00ACEE;
}

.social-icons-rounded ul li.twitter:hover a {
    color: #00ACEE;
    background: #ffffff;
}

.social-icons-rounded ul li.google-plus a {
    background: #DD4B39;
}

.social-icons-rounded ul li.google-plus:hover a {
    color: #DD4B39;
    background: #ffffff;
}

.social-icons-rounded ul li.pinterest a {
    background: #C8232C;
}

.social-icons-rounded ul li.pinterest:hover a {
    color: #C8232C;
    background: #ffffff;
}

.social-icons-rounded ul li.linkedin a {
    background: #0E76A8;
}

.social-icons-rounded ul li.linkedin:hover a {
    color: #0E76A8;
    background: #ffffff;
}

.social-icons-rounded ul li.flickr a {
    background: #FF0084;
}

.social-icons-rounded ul li.flickr:hover a {
    color: #FF0084;
    background: #ffffff;
}

.social-icons-rounded ul li.dribbble a {
    background: #EA4C89;
}

.social-icons-rounded ul li.dribbble:hover a {
    color: #EA4C89;
    background: #ffffff;
}

.social-icons-rounded ul li.google a {
    background: #DD4B39;
}

.social-icons-rounded ul li.google:hover a {
    color: #DD4B39;
    background: #ffffff;
}

.social-icons-rounded ul li.skype a {
    background: #00AFF0;
}

.social-icons-rounded ul li.skype:hover a {
    color: #00AFF0;
    background: #ffffff;
}

.social-icons-rounded ul li.youtube a {
    background: #C4302B;
}

.social-icons-rounded ul li.youtube:hover a {
    color: #C4302B;
    background: #ffffff;
}

.social-icons-rounded ul li.vimeo a {
    background: #86C9EF;
}

.social-icons-rounded ul li.vimeo:hover a {
    color: #86C9EF;
    background: #ffffff;
}

.social-icons-rounded ul li.yahoo a {
    background: #720E9E;
}

.social-icons-rounded ul li.yahoo:hover a {
    color: #720E9E;
    background: #ffffff;
}

.social-icons-rounded ul li.paypal a {
    background: #00588B;
}

.social-icons-rounded ul li.paypal:hover a {
    color: #00588B;
    background: #ffffff;
}

.social-icons-rounded ul li.instagram a {
    background: #D82E86;
}

.social-icons-rounded ul li.instagram:hover a {
    color: #D82E86;
    background: #ffffff;
}

.social-icons-style-2 ul li {
    margin: 0 5px;
}

.social-icons-style-2 ul li a {
    display: inline-block;
    height: 26px;
    width: 26px;
    border-radius: 100px;
    text-align: center;
    font-size: 12px;
    color: #2b2b35;
    background: #f7f7f7;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    line-height: 26px;
}

.social-icons-style-2 ul li a:hover {
    background: #ffb300;
    color: #ffffff;
}


/**
* Breadcrumb Area
*/

.cr-breadcrumb {
    padding-top: 60px;
    padding-bottom: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.cr-breadcrumb h2 {
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 5px;
    padding-right: 30px;
}

.cr-breadcrumb ul {
    padding-left: 0;
    margin-bottom: 5px;
}

.cr-breadcrumb ul li {
    list-style: none;
    display: inline-block;
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
}

.cr-breadcrumb ul li::after {
    content: "/";
    padding: 0 3px;
}

.cr-breadcrumb ul li:last-child::after {
    display: none;
}

.cr-breadcrumb ul li a {
    display: inline-block;
    color: #ffffff;
}

.cr-breadcrumb ul li a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 575px) {
    .cr-breadcrumb h2 {
        font-size: 24px;
    }
}

.bg-breadcrumb-1 {
    background-image: url(images/bg/bg-breadcrumb-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-2 {
    background-image: url(images/bg/bg-breadcrumb-2.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-3 {
    background-image: url(images/bg/bg-breadcrumb-3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-4 {
    background-image: url(images/bg/bg-breadcrumb-4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-5 {
    background-image: url(images/bg/bg-breadcrumb-5.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-6 {
    background-image: url(images/bg/bg-breadcrumb-6.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-7 {
    background-image: url(images/bg/bg-breadcrumb-7.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-8 {
    background-image: url(images/bg/bg-breadcrumb-8.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-9 {
    background-image: url(images/bg/bg-breadcrumb-9.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bg-breadcrumb-10 {
    background-image: url(images/bg/bg-breadcrumb-10.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}


/**
* Progress Bar
*/

.cr-progress {
    overflow: hidden;
    padding-bottom: 20px;
}

.cr-progress+.cr-progress {
    margin-top: 8px;
}

.cr-progress>h6 {
    font-size: 12px;
    font-weight: 700;
    color: #666666;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 3px;
    line-height: 24px;
}

.cr-progress .progress {
    border-radius: 0;
    background: #e1e1e1;
    height: 9px;
    position: relative;
    overflow: visible;
}

.cr-progress .progress-bar {
    background: #ffcd57;
    position: relative;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: all 1s ease-out 0s;
    -o-transition: all 1s ease-out 0s;
    transition: all 1s ease-out 0s;
}

.cr-progress .progress-bar.fill {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.cr-progress .progress-bar-count {
    position: absolute;
    top: 50%;
    left: 100%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: inline-block;
    height: 37px;
    width: 37px;
    line-height: 35px;
    border-radius: 100px;
    text-align: center;
    z-index: 1;
    background: #ffcd57;
    border: 4px solid #ffe199;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    font-size: 12px;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    color: #ffffff;
}

@media only screen and (max-width: 767px) {
    .cr-progress .progress-bar-count {
        -webkit-transform: translate(-70%, -50%);
        -ms-transform: translate(-70%, -50%);
        transform: translate(-70%, -50%);
        height: 18px;
        width: 37px;
        line-height: 18px;
    }
}


/**
* Accordion Styles
*/

.cr-accordion-wrap {
    border-top: 1px dashed #e1e1e1;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
}

.cr-accordion .cr-accordion-header a {
    border-bottom: 1px dashed #e1e1e1;
    border-collapse: collapse;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #003366;
    display: block;
    padding: 14px 0;
    padding-right: 20px;
    position: relative;
    padding-left: 70px;
}

.cr-accordion .cr-accordion-header a::before {
    content: "\f05d";
    font-family: 'fontAwesome';
    width: 70px;
    text-align: left;
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 14px;
}

.cr-accordion .cr-accordion-header a:not(.collapsed) {
    color: #2b2b35;
    font-weight: 700;
}

.cr-accordion .cr-accordion-header a:not(.collapsed)::before {
    content: "\f05c";
    font-weight: 400;
}

.cr-accordion .cr-accordion-body {
    padding: 30px;
    border-bottom: 1px dashed #e1e1e1;
}

.cr-accordion:last-child .cr-accordion-header a.collapsed {
    border-bottom: none;
}

.cr-accordion:last-child .cr-accordion-body {
    border-bottom: none;
}

.faq-questions {
    margin-top: -35px;
}

.faq-single-questions {
    margin-top: 70px;
}

.faq-wrapper h5 {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.5px;
    color: #2b2b35;
    text-transform: uppercase;
}

.faq-wrapper p {
    font-size: 14px;
    letter-spacing: 0.5px;
    font-style: italic;
    font-weight: 400;
}

.faq-single-title {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 0;
    background: #eeeded;
    border-radius: 4px;
    padding: 17px 25px;
    text-transform: uppercase;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}

.faq-single-title::before {
    content: "";
    position: absolute;
    width: calc(100% - 70px);
    height: 1px;
    background: #e1e1e1;
    top: 50%;
    right: 40px;
    z-index: -1;
}

.faq-single-title::after {
    content: "\f103";
    font-family: 'fontAwesome';
    position: absolute;
    right: 25px;
    top: 18px;
}

.faq-single-title span {
    background: #eeeded;
    position: relative;
    padding-right: 10px;
    z-index: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .cr-accordion .cr-accordion-header a {
        padding-left: 60px;
    }
    .cr-accordion .cr-accordion-header a::before {
        width: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .cr-accordion .cr-accordion-body {
        padding: 30px 20px;
    }
    .cr-accordion .cr-accordion-header a {
        padding-left: 40px;
    }
    .cr-accordion .cr-accordion-header a::before {
        width: 25px;
        left: 20px;
    }
}


/**
* Pagination Styles
*/

.cr-pagination ul {
    padding-left: 0;
    margin-bottom: 0;
    display: inline-block;
}

.cr-pagination ul li {
    list-style: none;
    display: inline-block;
    margin: 0 4px;
}

.cr-pagination ul li:first-child {
    margin-left: 0;
    margin-right: 15px;
}

.cr-pagination ul li:first-child a {
    width: auto;
    border: none;
    text-transform: uppercase;
}

.cr-pagination ul li:first-child a i {
    font-size: 14px;
    padding-right: 10px;
}

.cr-pagination ul li:last-child {
    margin-right: 0;
    margin-left: 15px;
}

.cr-pagination ul li:last-child a {
    width: auto;
    border: none;
    text-transform: uppercase;
}

.cr-pagination ul li:last-child a i {
    font-size: 14px;
    padding-left: 10px;
}

.cr-pagination ul li a {
    height: 35px;
    width: 35px;
    line-height: 33px;
    display: inline-block;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-size: 14px;
    color: #333333;
}

.cr-pagination ul li a:hover {
    color: #ffb300;
    border-color: #ffb300;
}

.cr-pagination ul li.is-active a {
    color: #ffffff;
    background: #ffb300;
    border-color: #ffb300;
}


/**
* Call to action
*/

.call-to-action-area {
    position: relative;
}

.call-to-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 20px;
}

.call-to-action h1,
.call-to-action h2,
.call-to-action h3,
.call-to-action h4,
.call-to-action h5,
.call-to-action h6 {
    color: #ffffff;
    margin-bottom: 0;
    padding-right: 30px;
}

.call-to-action h1 b,
.call-to-action h2 b,
.call-to-action h3 b,
.call-to-action h4 b,
.call-to-action h5 b,
.call-to-action h6 b {
    font-weight: 700;
}

.call-to-action h1:first-of-type,
.call-to-action h2:first-of-type,
.call-to-action h3:first-of-type,
.call-to-action h4:first-of-type,
.call-to-action h5:first-of-type,
.call-to-action h6:first-of-type {
    padding-top: 15px;
}

.call-to-action p {
    color: #ffffff;
    padding-right: 30px;
}

.call-to-action p:first-of-type {
    padding-top: 15px;
}

.call-to-action h5 {
    font-weight: 400;
    letter-spacing: 1.5px;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
}

.call-to-action.call-to-action-2 {
    padding: 40px 0;
}

.call-to-action.call-to-action-2 h3 {
    font-size: 30px;
    font-weight: 600;
    padding-top: 10px;
}

.call-to-action.call-to-action-2 p {
    font-style: italic;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 300;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .call-to-action.call-to-action-2 h3 {
        padding-top: 0;
    }
}

@media only screen and (max-width: 767px) {
    .call-to-action.call-to-action-2 {
        padding: 30px 0;
    }
    .call-to-action.call-to-action-2 h3 {
        font-size: 24px;
        padding-top: 0;
    }
}


/*******************
Blocks Styles
********************/


/**
* Header Styles
*/

.header {
    z-index: 80;
}

.header .header-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.header .header-inner .logo {
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header .header-inner .sa-navigation {
    -webkit-box-flex: 100;
    -ms-flex-positive: 100;
    flex-grow: 100;
}


/* Header Top Styles */

.header-top-area {
    border-bottom: 1px solid #41414a;
}

.header-top-area-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-top-area-inner ul {
    padding-left: 0;
    margin-bottom: 0;
}

.header-top-area-inner ul li {
    display: inline-block;
    list-style: none;
    color: #ffffff;
    padding: 0 30px;
    border-left: 1px solid #41414a;
    font-size: 14px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
}

.header-top-area-inner ul li:first-child {
    padding-left: 0;
    border-left: none;
}

.header-top-area-inner ul li i {
    color: #ffb300;
    font-size: 24px;
    padding-right: 10px;
    vertical-align: top;
}

.header-top-area-inner ul li a {
    display: inline-block;
    color: #ffffff;
}

.header-top-area-inner ul li a i {
    color: #ffb300;
}

.header-top-area-inner ul li a:hover {
    color: #ffb300;
}

.header-top-area-inner form {
    padding: 10px 0;
    position: relative;
    width: 250px;
}

.header-top-area-inner form input {
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    border: 1px solid #41414a;
    border-radius: 3px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    font-family: "Lato", sans-serif;
    padding-right: 35px;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.header-top-area-inner form input::-webkit-input-placeholder {
    color: #ffffff;
}

.header-top-area-inner form input:-moz-placeholder {
    color: #ffffff;
}

.header-top-area-inner form input::-moz-placeholder {
    color: #ffffff;
}

.header-top-area-inner form input:-ms-input-placeholder {
    color: #ffffff;
}

.header-top-area-inner form input:focus {
    border-color: #ffb300;
}

.header-top-area-inner form button[type="submit"],
.header-top-area-inner form button[type="submit"] {
    position: absolute;
    left: auto;
    top: 50%;
    height: 40px;
    margin-top: -20px;
    right: 0;
    color: #ffffff;
    font-size: 16px;
    border: none;
    padding-right: 15px;
    padding-left: 5px;
}

.header-top-area-inner form button[type="submit"]:hover,
.header-top-area-inner form button[type="submit"]:hover {
    color: #ffb300;
}


/* Header Bottom Area */

.header-style-1 .header-bottom-area {
    padding: 45px 0;
    position: relative;
    z-index: 10;
}

.header-style-1 .header-bottom-area::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 38px;
    background-image: url(images/icons/rulers.png);
    background-repeat: repeat-x;
    z-index: -1;
}

.header-style-1.header-transparent {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 81;
}


/* Sticky Header */

.sticky-header.is-sticky .header-bottom-area {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 82;
    -webkit-animation: crSlideInDown 0.5s ease-out 0s 1 both;
    animation: crSlideInDown 0.5s ease-out 0s 1 both;
    padding: 15px 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .header-style-1 .header-bottom-area {
        padding: 15px 0;
    }
    .header-top-area-inner ul li {
        padding: 0 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-top-area-inner {
        padding: 10px 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .header-top-area-inner ul {
        padding: 10px 0;
        text-align: center;
    }
    .header-top-area-inner ul li {
        padding: 0 15px;
    }
    .header-top-area-inner ul li:first-child {
        padding-left: 15px;
    }
    .header-top-area-inner form {
        width: 60%;
    }
}

@media only screen and (max-width: 767px) {
    .header-top-area-inner {
        padding: 10px 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .header-top-area-inner ul {
        padding: 10px 0;
        text-align: center;
    }
    .header-top-area-inner ul li {
        border: none;
    }
    .header-top-area-inner ul li:first-child {
        padding-left: 30px;
    }
    .header-top-area-inner form {
        width: 60%;
    }
    .header-style-1.sticky-header.is-sticky .mobile-menu-wrapper {
        position: fixed;
        -webkit-animation: crSlideInDown 0.5s ease-out 0s 1 both;
        animation: crSlideInDown 0.5s ease-out 0s 1 both;
        top: 0;
        left: 0;
        right: 0;
        z-index: 82;
        -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    }
}

@media only screen and (max-width: 575px) {
    .header-top-area-inner form {
        width: 100%;
    }
    .header-top-area-inner ul li {
        margin-bottom: 10px;
    }
}


/* Header Style 2 Styles*/

.header-style-2 .header-bottom-area {
    padding: 25px 0;
    z-index: 10;
}

.header-style-2.header-transparent .header-bottom-area {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 81;
}

.header-style-2.sticky-header.is-sticky .header-bottom-area {
    background: #ffffff;
    position: fixed;
}


/* Mobile Menu Style */

.mobile-menu-wrapper {
    background: #2b2b35;
}

.mobile-menu::after {
    content: "";
    clear: both;
    display: table;
}

.mobile-menu .social-icons {
    position: absolute;
    top: 15px;
    right: 0;
    width: auto;
    z-index: 100;
}

.mobile-menu .social-icons ul li {
    margin: 0;
}

.mobile-menu .social-icons ul li a {
    color: #ffffff;
    padding: 10px 8px;
    display: inline-block;
}

.mobile-menu .social-icons ul li:first-child a {
    padding-left: 0;
}

.mobile-menu .social-icons ul li:last-child a {
    padding-right: 0;
}

.sticky-header.is-sticky .mobile-menu-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 82;
    background: #333333;
    -webkit-animation: crSlideInDown 0.5s ease-out 0s 1 both;
    animation: crSlideInDown 0.5s ease-out 0s 1 both;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 575px) {
    .mobile-menu .social-icons {
        position: relative;
        top: 0;
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 5px 0;
    }
    .sticky-header.is-sticky .mobile-menu .social-icons {
        display: none;
    }
}


/**
* Navigation Styles
*/

.sa-navigation ul {
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 0;
}

.sa-navigation ul li {
    display: inline-block;
    font-size: 14px;
}

.sa-navigation ul li a {
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: #ffffff;
    font-size: 14px;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    padding: 4px 15px;
    border-radius: 3px;
}

.sa-navigation ul li:hover>a {
    background: #ffb300;
    color: #2b2b35;
}

.header-style-2 .sa-navigation ul li a {
    color: #003366;
}

.header-style-2 .sa-navigation ul li:hover>a {
    color: #2b2b35;
}


/* Dropdown Styles */

.sa-navigation ul li.sa-dropdown {
    position: relative;
}

.sa-navigation ul li.sa-dropdown>a::after {
    content: "\f107";
    font-family: 'fontAwesome';
    padding-left: 5px;
}

.sa-navigation ul li.sa-dropdown ul {
    border-bottom: 3px solid #ffb300;
    position: absolute;
    left: 0;
    width: 250px;
    top: calc(100% + 30px);
    background: rgba(21, 21, 21, 0.95);
    text-align: left;
    padding: 15px 0;
    -webkit-box-shadow: 0 2px 3px rgba(255, 255, 255, 0.15);
    box-shadow: 0 2px 3px rgba(255, 255, 255, 0.15);
    -webkit-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    z-index: 10;
}

.sa-navigation ul li.sa-dropdown ul::before {
    content: "";
    position: absolute;
    left: 30px;
    top: auto;
    bottom: 100%;
    border-width: 15px;
    border-style: solid;
    border-color: transparent transparent rgba(21, 21, 21, 0.95) transparent;
}

.sa-navigation ul li.sa-dropdown ul li {
    display: block;
    position: relative;
}

.sa-navigation ul li.sa-dropdown ul li.sa-sub-dropdown>a {
    position: relative;
    padding-right: 20px;
}

.sa-navigation ul li.sa-dropdown ul li.sa-sub-dropdown>a::after {
    content: "\f105";
    font-family: 'fontAwesome';
    padding-left: 5px;
    position: absolute;
    right: 15px;
}

.sa-navigation ul li.sa-dropdown ul li a {
    display: block;
    padding: 3px 20px;
    font-family: "Lato", sans-serif;
    color: #ffffff;
    font-weight: 400;
    border-radius: 0;
}

.sa-navigation ul li.sa-dropdown ul li:hover>a {
    color: #ffb300;
    background: transparent;
}

.sa-navigation ul li.sa-dropdown ul li ul {
    left: calc(100% + 1px);
    top: 0;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
}

.sa-navigation ul li.sa-dropdown ul li ul::before {
    display: none;
}

.sa-navigation ul li.sa-dropdown ul li ul li {
    display: block;
}

.sa-navigation ul li.sa-dropdown ul li ul li a {
    display: block;
}

.sa-navigation ul li.sa-dropdown ul li:hover ul {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

.sa-navigation ul li.sa-dropdown:hover>ul {
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}


/**
* Banner Styles
*/

.banner {
    min-height: 690px;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    overflow: hidden;
    position: relative;
}

.banner-text {
    padding: 150px 0;
}

.banner-text h1,
.banner-text h2,
.banner-text h3,
.banner-text h4,
.banner-text h5,
.banner-text h6,
.banner-text p {
    color: #ffffff;
}

.banner-text h1 {
    text-transform: uppercase;
    font-size: 60px;
    font-weight: 700;
    position: relative;
    padding-bottom: 20px;
}

.banner-text h1::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 7px;
    width: 70px;
    background: #ffffff;
}

.banner-text h3 {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0;
}

.banner-text h3 b {
    font-weight: 700;
}

.banner-text h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.banner-text .cr-btn {
    margin-top: 20px;
}

.banner-text.text-center h1::after {
    left: 50%;
    margin-left: -35px;
}


/* Banner Style 2 */

.banner-text.banner-text-2 h1 {
    position: relative;
    display: inline-block;
    padding: 0 20px;
    font-size: 70px;
}

.banner-text.banner-text-2 h1::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    border-left: 1px solid #ffffff;
    border-top: 1px solid #ffffff;
}

.banner-text.banner-text-2 h1::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
}

.banner-text.banner-text-2 h3 {
    margin-bottom: 20px;
}


/* Banner Style 3 */

.banner-text-3 h3 {
    margin-bottom: 20px;
}

.banner-text-3 h1 {
    padding: 0;
    font-size: 80px;
    line-height: 64px;
}

.banner-text-3 h1:after {
    display: none;
}


/* Banner Slider Animation */

.banner-slider-active .banner .banner-layer {
    -webkit-transition: all 0.8s ease-out 0.3s;
    -o-transition: all 0.8s ease-out 0.3s;
    transition: all 0.8s ease-out 0.3s;
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
}

.banner-slider-active .banner .banner-layer.banner-layer-2 {
    -webkit-transition: all 0.8s ease-out 0.3s;
    -o-transition: all 0.8s ease-out 0.3s;
    transition: all 0.8s ease-out 0.3s;
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0;
}

.banner-slider-active .banner .banner-text>* {
    -webkit-transition: all 0.8s ease-out 0.5s;
    -o-transition: all 0.8s ease-out 0.5s;
    transition: all 0.8s ease-out 0.5s;
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden;
}

.banner-slider-active .banner .banner-text h3 {
    -webkit-transition: all 0.8s ease-out 0.5s;
    -o-transition: all 0.8s ease-out 0.5s;
    transition: all 0.8s ease-out 0.5s;
}

.banner-slider-active .banner .banner-text h1 {
    -webkit-transition: all 0.8s ease-out 0.7s;
    -o-transition: all 0.8s ease-out 0.7s;
    transition: all 0.8s ease-out 0.7s;
}

.banner-slider-active .banner .banner-text h6 {
    -webkit-transition: all 0.8s ease-out 0.9s;
    -o-transition: all 0.8s ease-out 0.9s;
    transition: all 0.8s ease-out 0.9s;
}

.banner-slider-active .banner .banner-text>.cr-btn {
    -webkit-transition: all 0.8s ease-out 1.1s;
    -o-transition: all 0.8s ease-out 1.1s;
    transition: all 0.8s ease-out 1.1s;
    -webkit-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 0;
}

.banner-slider-active .banner.slick-active .banner-layer {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.banner-slider-active .banner.slick-active .banner-layer.banner-layer-2 {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}

.banner-slider-active .banner.slick-active .banner-text>* {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.banner-slider-active .banner.slick-active .banner-text>.cr-btn {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
}


/* Banner Slider Elements */

.banner-slider-active {
    position: relative;
}

.banner-slider-active .cr-navigation {
    position: absolute;
    top: 50%;
    height: 60px;
    width: 60px;
    margin-top: -30px;
    display: inline-block;
    border-radius: 100%;
    background: rgba(255, 179, 0, 0.2);
    border: 3px solid #ffb300;
    font-size: 32px;
    text-align: center;
    padding: 10px 0;
    color: #ffb300;
    z-index: 1;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    opacity: 0;
}

.banner-slider-active .cr-navigation:hover {
    background: #ffb300;
    color: #ffffff;
}

.banner-slider-active .cr-navigation.cr-navigation-prev {
    left: -5%;
    right: auto;
}

.banner-slider-active .cr-navigation.cr-navigation-next {
    left: auto;
    right: -5%;
}

.banner-slider-active:hover .cr-navigation {
    opacity: 1;
}

.banner-slider-active:hover .cr-navigation.cr-navigation-prev {
    left: 4%;
}

.banner-slider-active:hover .cr-navigation.cr-navigation-next {
    right: 4%;
}

.banner-slider-active .cr-slider-dots {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-bottom: 0;
    padding-left: 0;
    bottom: 50px;
    line-height: 1;
}

.banner-slider-active .cr-slider-dots li {
    display: inline-block;
    margin: 0 7px;
    line-height: 1;
}

.banner-slider-active .cr-slider-dots li:first-child {
    margin-left: 0;
}

.banner-slider-active .cr-slider-dots li:last-child {
    margin-right: 0;
}

.banner-slider-active .cr-slider-dots li button {
    border-radius: 100px;
    height: 6px;
    width: 10px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    font-size: 0;
}

.banner-slider-active .cr-slider-dots li.slick-active button {
    width: 35px;
    background: white;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .banner-text h1 {
        font-size: 48px;
    }
    .banner-text .cr-btn {
        margin-top: 35px;
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-prev {
        left: 2%;
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-next {
        right: 2%;
    }
    .banner-text-3 h1 {
        font-size: 66px;
        line-height: 60px;
        margin-bottom: 0;
    }
    .banner-layer-3 {
        max-height: calc(100% - 90px);
    }
    .banner-text.banner-text-2 h1 {
        font-size: 60px;
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .banner {
        min-height: 550px;
    }
    .banner-text {
        padding: 100px 0;
    }
    .banner-text h1 {
        font-size: 48px;
    }
    .banner-text h3 {
        font-size: 26px;
    }
    .banner-text .cr-btn {
        margin-top: 35px;
    }
    .banner-slider-active .cr-navigation {
        height: 45px;
        width: 45px;
        margin-top: -22.5px;
        border-width: 2px;
        font-size: 22px;
        padding: 8px 0;
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-prev {
        left: 2%;
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-next {
        right: 2%;
    }
    .banner-text-3 h1 {
        font-size: 66px;
        line-height: 60px;
        margin-bottom: 0;
    }
    .banner-layer-3 {
        max-height: calc(100% - 90px);
    }
    .banner-text.banner-text-2 h1 {
        font-size: 52px;
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .banner {
        min-height: auto;
    }
    .banner-text {
        padding: 100px 0;
        text-align: center;
    }
    .banner-text h1 {
        font-size: 42px;
    }
    .banner-text h1::after {
        left: 50%;
        margin-left: -35px;
    }
    .banner-text h2 {
        font-size: 27px;
    }
    .banner-text h4 {
        font-size: 16px;
    }
    .banner-text .cr-btn {
        margin-top: 25px;
    }
    .banner-slider-active .cr-navigation {
        height: 45px;
        width: 45px;
        border-width: 2px;
        font-size: 22px;
        padding: 8px 0;
        top: auto;
        bottom: 30px;
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-prev {
        left: auto;
        right: calc( 50% + 12px);
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-next {
        right: auto;
        left: calc( 50% + 12px);
    }
    .banner-layer {
        margin: 0 auto;
        left: auto;
        margin-top: 30px;
        position: relative;
        max-height: 300px;
    }
    .banner-layer-2 {
        margin-top: 0;
    }
    .banner-slider-active .banner .banner-layer.banner-layer-2 {
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    .banner-text.banner-text-2 h1 {
        position: relative;
        display: inline-block;
        padding: 0 20px;
        font-size: 52px;
    }
    .banner-text.banner-text-2 h1::after {
        left: auto;
        right: 0;
        margin-left: 0;
    }
    .banner-text-3 h1 {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 0;
    }
    .banner-text.banner-text-2 h1 {
        font-size: 52px;
        margin-bottom: 0;
    }
    .banner-layer-3 {
        max-height: 350px;
        right: auto;
    }
}

@media only screen and (max-width: 767px) {
    .banner {
        min-height: auto;
    }
    .banner-text {
        padding: 100px 0;
        text-align: center;
    }
    .banner-text h1 {
        font-size: 42px;
    }
    .banner-text h1::after {
        left: 50%;
        margin-left: -35px;
        height: 5px;
    }
    .banner-text h2 {
        font-size: 27px;
    }
    .banner-text h3 {
        font-size: 18px;
    }
    .banner-text h4 {
        font-size: 16px;
    }
    .banner-text h6 {
        font-size: 14px;
    }
    .banner-text .cr-btn {
        margin-top: 25px;
    }
    .banner-slider-active .cr-navigation {
        height: 40px;
        width: 40px;
        border-width: 2px;
        font-size: 22px;
        padding: 5px 0;
        top: auto;
        bottom: 30px;
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-prev {
        left: auto;
        right: calc( 50% + 12px);
    }
    .banner-slider-active:hover .cr-navigation.cr-navigation-next {
        right: auto;
        left: calc( 50% + 12px);
    }
    .banner-layer {
        margin: 0 auto;
        left: auto;
        margin-top: 30px;
        position: relative;
        max-height: 300px;
    }
    .banner-layer-2 {
        margin-top: 0;
    }
    .banner-slider-active .banner .banner-layer.banner-layer-2 {
        -webkit-transform: translateX(-100px);
        -ms-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    .banner-text.banner-text-2 h1 {
        position: relative;
        display: inline-block;
        padding: 0 20px;
        font-size: 52px;
    }
    .banner-text.banner-text-2 h1::after {
        left: auto;
        right: 0;
        margin-left: 0;
    }
    .banner-text-3 h1 {
        font-size: 42px;
        line-height: 42px;
        margin-bottom: 0;
    }
    .banner-text-3 h3 {
        margin-bottom: 10px;
    }
    .banner-text.banner-text-2 h3 {
        font-size: 20px;
    }
    .banner-text.banner-text-2 h1 {
        font-size: 38px;
        margin-bottom: 0;
        line-height: 38px;
        padding: 5px 10px;
    }
    .banner-layer-3 {
        max-height: 350px;
        right: auto;
    }
}

@media only screen and (max-width: 575px) {
    .banner-text h1 {
        font-size: 32px;
    }
}


/**
* About Styles
*/

.about-area-content img {
    margin-bottom: 20px;
}

.about-area-content h5 {
    font-weight: 700;
    margin-bottom: 28px;
}

.about-area-content p {
    margin-bottom: 30px;
}

.about-area-content .cr-btn {
    margin-top: 30px;
}

.about-area-content.about-area-content-2 h5 {
    margin-top: -4px;
    margin-bottom: 20px;
}

.about-area-content.about-area-content-2 .cr-btn {
    margin-top: 0;
}

.about-area-timeline-2 {
    border-top: 1px solid #e5e5e5;
    margin-left: 0;
    padding-top: 20px;
}

.about-area-timeline-2 .about-timeline-single {
    padding-top: 30px;
}

.about-area-timeline-2 .about-timeline-single:last-child {
    padding-top: 30px;
}

.about-timeline-single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-bottom: 40px;
}

.about-timeline-single .about-timeline-icon {
    -ms-flex-item-align: start;
    align-self: flex-start;
    height: auto;
    max-width: 70px;
    line-height: 66px;
    text-align: center;
    display: inline-block;
    font-size: 40px;
    color: #ffb300;
}

.about-timeline-single .about-timeline-icon img {
    width: 100%;
}

.about-timeline-single .about-timeline-content {
    -ms-flex-negative: 100;
    flex-shrink: 100;
    padding-left: 20px;
}

.about-timeline-single .about-timeline-content h5 {
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: -3px;
}

.about-timeline-single:last-child {
    padding-bottom: 0;
}

.about-timeline-single:hover .about-timeline-icon i {
    -webkit-animation: crBounceIn 0.5s ease-in 1;
    animation: crBounceIn 0.5s ease-in 1;
}

.about-area-images .large-image {
    margin-left: auto;
    margin-right: 0;
    display: block;
    position: relative;
    z-index: 2;
}

.about-area-images .small-image {
    margin-right: auto;
    margin-left: 0;
    display: block;
    margin-top: -275px;
    position: relative;
    z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-area-content .cr-btn {
        margin-top: 0;
    }
    .about-area-images .large-image {
        width: 100%;
    }
    .about-area-images .small-image {
        margin-top: -130px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-area-content .cr-btn {
        margin-top: 0;
    }
    .about-area-images {
        margin-top: 30px;
    }
    .about-area-images .large-image {
        margin: inherit;
        float: left;
        margin-right: 30px;
    }
    .about-area-images .small-image {
        margin: inherit;
        float: left;
    }
}

@media only screen and (max-width: 767px) {
    .about-area-content .cr-btn {
        margin-top: 0;
    }
    .about-area-images {
        margin-top: 30px;
    }
    .about-area-images .large-image {
        margin: inherit;
        float: left;
        margin-right: 30px;
    }
    .about-area-images .small-image {
        margin: inherit;
        float: left;
    }
}

@media only screen and (max-width: 767px) {
    .about-timeline-single .about-timeline-icon {
        max-width: 40px;
        line-height: 40px;
    }
}


/* About Page Styles */

.about-page-skill-content {
    width: 60%;
    margin: 0 auto;
    padding: 50px 0;
}

.about-page-skill-content>h3 {
    text-transform: uppercase;
    font-weight: 800;
    position: relative;
    padding-bottom: 20px;
}

.about-page-skill-content>h3::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 50px;
    background: #2b2b35;
}

.about-page-skill-content .progress-bars {
    padding-top: 40px;
}

.pg-about-content {
    padding: 0 35px;
    margin-bottom: 30px;
}

.pg-about-content h2 {
    font-size: 30px;
    font-weight: 800;
}

.pg-about-content p {
    font-size: 16px;
    line-height: 35px;
    font-family: "Lato", sans-serif;
    margin-top: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .about-page-skill-content {
        width: 85%;
    }
    .about-page-skill-content .progress-bars {
        padding-top: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-page-skill-content {
        width: 85%;
    }
    .about-page-skill-content .progress-bars {
        padding-top: 20px;
    }
    .about-page-skills-image {
        width: 85%;
        margin: 0 auto;
        margin-top: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-page-skill-content {
        width: 90%;
    }
    .about-page-skill-content .progress-bars {
        padding-top: 20px;
    }
    .about-page-skills-image {
        width: 90%;
        margin: 0 auto;
        margin-top: 60px;
    }
    .pg-about-content p {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .about-page-skill-content {
        width: 90%;
    }
    .about-page-skill-content .progress-bars {
        padding-top: 20px;
    }
    .about-page-skills-image {
        width: 90%;
        margin: 0 auto;
        margin-top: 60px;
    }
    .pg-about-content p {
        margin-top: 0;
    }
    .pg-about-content h2 {
        font-size: 25px;
    }
    .pg-about-content p {
        line-height: 28px;
    }
}

@media only screen and (max-width: 575px) {
    .pg-about-content {
        padding: 0;
    }
    .about-page-skill-content>h3 {
        font-size: 21px;
    }
}


/**
 * Service styels
 */

.single-service {
    margin: 38px 0;
    padding-right: 25px;
}

.single-service .signle-service-head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.single-service .signle-service-head span {
    width: 60px;
    font-size: 36px;
    color: #ffb300;
}

.single-service .signle-service-head h6 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    -ms-flex-negative: 100;
    flex-shrink: 100;
}

.single-service p {
    color: #666666;
    margin-bottom: 0;
}

.single-service:hover .signle-service-head span {
    -webkit-animation: crBounceIn 0.6s ease-in-out 1 both;
    animation: crBounceIn 0.6s ease-in-out 1 both;
}

.single-service.single-service-2 {
    padding-right: 0;
    margin: 15px 0;
}

.single-service.single-service-2 .signle-service-head {
    margin-bottom: 20px;
}

.single-service.single-service-2 .signle-service-head span {
    height: 70px;
    width: 70px;
    border: 2px solid #ffb300;
    border-radius: 1000px;
    display: inline-block;
    text-align: center;
    line-height: 70px;
    font-size: 30px;
    margin-right: 15px;
    position: relative;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-service.single-service-2 .signle-service-head span::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 3px solid #ffb300;
    border-radius: 1000px;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-service.single-service-2 .signle-service-head h6 {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0;
}

.single-service.single-service-2:hover .signle-service-head span {
    background: #ffb300;
    color: #ffffff;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.single-service.single-service-2:hover .signle-service-head span::before {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1.23);
    -ms-transform: scale(1.23);
    transform: scale(1.23);
}

.pg-service-thumb img {
    width: 100%;
}

.pg-service-content {
    padding: 30px 50px;
}

.pg-service-content p {
    padding-top: 20px;
    font-size: 16px;
    line-height: 36px;
}

.pg-service-content .cr-btn {
    margin-top: 65px;
}

.service-area-services {
    position: relative;
}

.service-area-image {
    position: absolute;
    left: 30px;
    bottom: 0;
    max-height: calc(100% - 30px);
    max-width: 34%;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
    .single-service {
        margin: 30px 0;
    }
    .pg-service-content .section-title {
        padding-bottom: 20px;
    }
    .pg-service-content p {
        padding-top: 0;
    }
    .pg-service-content .cr-btn {
        margin-top: 10px;
    }
    .service-area-image {
        left: 0;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .single-service {
        margin: 30px 0;
    }
    .service-area-image {
        left: 0;
        bottom: 50%;
        -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
        transform: translateY(50%);
    }
    .pg-service-content {
        padding: 30px;
    }
    .pg-service-content .section-title {
        padding-bottom: 20px;
    }
    .pg-service-content p {
        padding-top: 0;
        font-size: 14px;
        line-height: 30px;
    }
    .pg-service-content .cr-btn {
        margin-top: 10px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-service {
        margin: 30px 0;
        padding-right: 0;
    }
    .service-area-image {
        left: 0;
        bottom: 50%;
        max-width: 42%;
        -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
        transform: translateY(50%);
    }
    .pg-service-thumb {
        padding: 30px;
    }
    .pg-service-content {
        padding: 30px;
        padding-bottom: 60px;
    }
    .pg-service-content .section-title {
        padding-bottom: 20px;
    }
    .pg-service-content p {
        padding-top: 0;
    }
    .pg-service-content .cr-btn {
        margin-top: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-service {
        margin: 30px 0;
        padding-right: 0;
    }
    .single-service {
        margin: 15px 0;
    }
    .pg-service-thumb {
        padding: 30px;
        padding-bottom: 0;
    }
    .pg-service-content {
        padding: 60px 30px;
    }
    .pg-service-content .section-title {
        padding-bottom: 20px;
    }
    .pg-service-content p {
        padding-top: 0;
    }
    .pg-service-content .cr-btn {
        margin-top: 10px;
    }
    .service-area-image {
        display: none;
    }
}

@media only screen and (max-width: 767px) {
    .single-service {
        margin: 20px 0;
        padding-right: 0;
    }
    .single-service {
        margin: 15px 0;
    }
    .pg-service-thumb {
        padding: 30px;
        padding-bottom: 0;
    }
    .pg-service-content {
        padding: 60px 30px;
    }
    .pg-service-content .section-title {
        padding-bottom: 20px;
    }
    .pg-service-content p {
        padding-top: 0;
        font-size: 14px;
        line-height: 30px;
    }
    .pg-service-content .cr-btn {
        margin-top: 10px;
    }
    .service-area-image {
        display: none;
    }
}

@media only screen and (max-width: 575px) {
    .pg-service-thumb {
        padding: 20px;
        padding-bottom: 0;
    }
    .pg-service-content {
        padding: 50px 20px;
    }
}


/**
* Projects Styles
*/

.projects-filters,
.projects-filters-tab {
    font-size: 0;
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: -3px;
    margin-left: -3px;
}

.projects-filters button,
.projects-filters a,
.projects-filters-tab button,
.projects-filters-tab a {
    margin-top: 3px;
    display: inline-block;
    background: #ffffff;
    border: none;
    outline: none;
    font-size: 12px;
    color: #666666;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    padding: 16px 25px;
    margin-left: 3px;
    letter-spacing: 0.5px;
    position: relative;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    line-height: 1.2;
}

.projects-filters button::after,
.projects-filters a::after,
.projects-filters-tab button::after,
.projects-filters-tab a::after {
    content: "";
    position: absolute;
    right: 2px;
    top: 2px;
    height: 10px;
    width: 10px;
    border-width: 5px;
    border-style: solid;
    border-color: #2b2b35 #2b2b35 transparent transparent;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.projects-filters button:hover,
.projects-filters button.is-checked,
.projects-filters button.active,
.projects-filters a:hover,
.projects-filters a.is-checked,
.projects-filters a.active,
.projects-filters-tab button:hover,
.projects-filters-tab button.is-checked,
.projects-filters-tab button.active,
.projects-filters-tab a:hover,
.projects-filters-tab a.is-checked,
.projects-filters-tab a.active {
    background: #ffb300;
    color: #2b2b35;
}

.projects-filters button.is-checked::after,
.projects-filters button.active::after,
.projects-filters a.is-checked::after,
.projects-filters a.active::after,
.projects-filters-tab button.is-checked::after,
.projects-filters-tab button.active::after,
.projects-filters-tab a.is-checked::after,
.projects-filters-tab a.active::after {
    visibility: visible;
    opacity: 1;
}


/* Single Project Styles */

.single-project {
    margin-bottom: 30px;
    background: #ffffff;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    position: relative;
}

.single-project .single-project-thumb {
    overflow: hidden;
}

.single-project .single-project-thumb img {
    width: 100%;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.single-project .single-project-content {
    padding: 25px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background: rgba(255, 255, 255, 0.75);
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-project .single-project-content h5 {
    margin-bottom: 0;
    color: #333333;
    font-weight: 600;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-project .single-project-content h5 a {
    color: #333333;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-project .single-project-content h5 a:hover {
    text-decoration: underline;
}

.single-project .single-project-content .loadmore-btn-holder {
    padding-top: 15px;
    display: none;
}

.single-project .single-project-content a.cr-loadmore-btn {
    background: #2b2b35;
    border-color: #2b2b35;
    color: #ffffff;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    padding: 10px 15px;
}

.single-project .project-tags {
    margin-bottom: 0;
    padding-left: 0;
    font-size: 0;
}

.single-project .project-tags li {
    display: inline-block;
    list-style: none;
    font-size: 12px;
    color: #666666;
    font-style: italic;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
}

.single-project .project-tags li::after {
    content: "/";
    padding: 0 4px;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-project .project-tags li:last-child::after {
    display: none;
}

.single-project .project-tags li a {
    display: inline-block;
    color: #666666;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-project .project-tags li a:hover {
    color: #ffb300;
}

.single-project:hover .single-project-content {
    background: #ffb300;
}

.single-project:hover .single-project-thumb img {
    -webkit-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px);
}

.single-project:hover .project-tags li a {
    color: #2b2b35;
}

.single-project:hover .project-tags li a:hover {
    text-decoration: underline;
}

.single-project.single-project-2 .single-project-thumb {
    position: relative;
}

.single-project.single-project-2 .single-project-thumb::before {
    content: "\f00e";
    font-family: 'fontAwesome';
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 20px;
    height: 48px;
    line-height: 48px;
    width: 48px;
    background: #ffb300;
    color: #ffffff;
    margin-left: -24px;
    margin-top: -24px;
    border-radius: 100px;
    text-align: center;
    z-index: 1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    visibility: hidden;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.single-project.single-project-2:hover .single-project-thumb::before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    visibility: visible;
    cursor: pointer;
}

.bg-grey .single-project {
    background: #ffffff;
}

.bg-grey .single-project:hover {
    background: #444444;
}

.bg-white .single-project {
    background: #f5f5f5;
}

.bg-white .single-project:hover {
    background: #444444;
}

.sa-projects-slider-active {
    padding-left: 30px;
    padding-right: 30px;
}

.sa-projects-slider-active .single-project {
    margin-bottom: 0;
}

.sa-projects-slider-active.slider-navigation-style-1 {
    padding-bottom: 95px;
}

.sa-projects-slider-active.slider-navigation-style-1 .cr-navigation {
    top: auto;
    bottom: 0;
}

.sa-projects-slider-active.slider-navigation-style-1 .cr-navigation.cr-navigation-prev {
    right: calc( 50% + 5px);
    left: auto;
}

.sa-projects-slider-active.slider-navigation-style-1 .cr-navigation.cr-navigation-next {
    left: calc( 50% + 5px);
    right: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-project .single-project-content {
        padding: 20px;
    }
    .single-project .single-project-content h5 {
        font-size: 16px;
    }
    .sa-projects-slider-active.slider-navigation-style-1 {
        padding-bottom: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .projects-filters,
    .projects-filters-tab {
        margin-bottom: 50px;
        text-align: left;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

@media only screen and (max-width: 767px) {
    .projects-filters,
    .projects-filters-tab {
        margin-bottom: 30px;
        text-align: left;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-left: -10px;
    }
    .projects-filters button,
    .projects-filters a,
    .projects-filters-tab button,
    .projects-filters-tab a {
        margin-left: 10px;
        margin-bottom: 10px;
        padding: 12px 25px;
    }
    .projects-filters button:first-child,
    .projects-filters a:first-child,
    .projects-filters-tab button:first-child,
    .projects-filters-tab a:first-child {
        margin-left: 10px;
    }
    .single-project .single-project-content {
        padding: 20px;
    }
    .single-project .single-project-content h5 {
        font-size: 16px;
    }
    .projects-tab-content {
        max-width: 485px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .projects-filters,
    .projects-filters-tab {
        text-align: left;
    }
}

@media only screen and (max-width: 767px) {
    .projects-filters,
    .projects-filters-tab {
        text-align: left;
    }
}

@media only screen and (max-width: 575px) {
    .sa-projects-slider-active {
        padding-left: 15px;
        padding-right: 15px;
    }
}


/**
* Brand Logos
*/

.brand-logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
}

.brand-logos .brand-logo {
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    text-align: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    padding: 16px 20px;
    background: transparent;
    -webkit-transition: 0.4s ease-out 0s;
    -o-transition: 0.4s ease-out 0s;
    transition: 0.4s ease-out 0s;
    max-height: 102px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.brand-logos .brand-logo img {
    max-height: 100%;
}

.brand-logos .brand-logo:hover {
    background: rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-logos .brand-logo {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}

@media only screen and (max-width: 767px) {
    .brand-logos .brand-logo {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 30px 20px;
    }
}

@media only screen and (max-width: 575px) {
    .brand-logos .brand-logo {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 30px 20px;
    }
}


/* Brand Logo 2 */

.brand-logos-slider {
    position: relative;
}

.brand-logos-slider .brand-logo {
    text-align: center;
}

.brand-logos-slider .brand-logo a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: transparent;
    -webkit-transition: 0.4s ease-out 0s;
    -o-transition: 0.4s ease-out 0s;
    transition: 0.4s ease-out 0s;
    min-height: 180px;
    max-height: 180px;
}

.brand-logos-slider .brand-logo a:hover {
    background: #f3f3f3;
}

.brand-logos-slider .cr-navigation {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 3px;
    background: transparent;
    border: 1px solid #2b2b35;
    font-size: 22px;
    text-align: center;
    padding: 6px 0;
    color: #2b2b35;
    z-index: 2;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    visibility: hidden;
    opacity: 0;
}

.brand-logos-slider .cr-navigation:hover {
    background: #ffb300;
    border-color: #ffb300;
    color: #ffffff;
}

.brand-logos-slider .cr-navigation.cr-navigation-prev {
    left: auto;
    right: 100%;
}

.brand-logos-slider .cr-navigation.cr-navigation-next {
    left: 100%;
    right: auto;
}

.brand-logos-slider:hover .cr-navigation {
    opacity: 1;
    visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .brand-logos-slider .cr-navigation.cr-navigation-prev {
        left: 0;
        right: auto;
    }
    .brand-logos-slider .cr-navigation.cr-navigation-next {
        left: auto;
        right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .brand-logos-slider .cr-navigation.cr-navigation-prev {
        left: 0;
        right: auto;
    }
    .brand-logos-slider .cr-navigation.cr-navigation-next {
        left: auto;
        right: 0;
    }
}


/**
* Work Process
*/

.work-process-area[data-overlay]:before {
    background: #4e88c0;
}

.work-process {
    margin: 15px 0;
}

.work-process-icon {
    display: inline-block;
    height: 137px;
    width: 137px;
    border-radius: 1000px;
    border: 2px solid #ffffff;
    text-align: center;
    line-height: 133px;
    color: #ffffff;
    font-size: 60px;
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.work-process-badge {
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    background: #ffb300;
    font-size: 16px;
    line-height: 32px;
    border-radius: 100px;
    border: 4px solid #ffffff;
}

.work-process h5 {
    margin-top: 30px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: underline;
}

.work-process:hover .work-process-icon {
    background: #ffb300;
    border-color: #ffb300;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .work-process-icon {
        height: 120px;
        width: 120px;
        line-height: 118px;
        font-size: 45px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .work-process-icon {
        height: 110px;
        width: 110px;
        line-height: 106px;
        font-size: 38px;
    }
    .work-process-badge {
        left: -4px;
        top: -4px;
    }
    .work-process h5 {
        margin-top: 15px;
    }
}


/**
* Blog Item Styles
*/

.blog-item {
    margin-bottom: 30px;
}

.blog-item .blog-item-thumb {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 25px;
}

.blog-item .blog-item-thumb img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    width: 100%;
}

.blog-item .blog-item-thumb::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 179, 0, 0.5);
}

.blog-item .blog-item-title {
    color: #2b2b35;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
    margin-bottom: 8px;
}

.blog-item .blog-item-title a {
    color: #2b2b35;
}

.blog-item .blog-item-title a:hover {
    color: #ffb300;
}

.blog-item p {
    margin-bottom: 20px;
}

.blog-item .blog-item-meta {
    width: 100%;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
}

.blog-item .blog-item-meta::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 120px;
    left: 30px;
    top: 0;
    background: #ebebec;
}

.blog-item .blog-item-meta span {
    padding-right: 20px;
    color: #666666;
}

.blog-item .blog-item-meta span:last-child {
    padding-right: 0;
}

.blog-item .blog-item-meta span i {
    padding-right: 4px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.blog-item .blog-item-meta span a {
    color: #666666;
}

.blog-item .blog-item-meta span a:hover {
    color: #ffb300;
}

.blog-item .blog-item-meta span a:hover i {
    color: #ffb300;
}

.blog-item .cr-btn,
.blog-item a.cr-btn {
    margin-top: 35px;
}

.blog-item .cr-btn::before,
.blog-item .cr-btn::after,
.blog-item a.cr-btn::before,
.blog-item a.cr-btn::after {
    display: none;
}

.blog-item .cr-btn:hover,
.blog-item a.cr-btn:hover {
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
    background: #ffb300 !important;
    border-color: #ffb300 !important;
}

.blog-item:hover .blog-item-thumb img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.blog-item-slider-active .blog-item {
    margin-bottom: 0;
}


/* Popular Post */

.popular-post .popular-post-thumb {
    display: block;
    padding-bottom: 15px;
}

.popular-post .popular-post-thumb img {
    width: 100%;
}

.popular-post h6 {
    font-size: 13px;
    font-weight: 700;
    color: #2b2b35;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.popular-post h6 a {
    color: #2b2b35;
}

.popular-post h6 a:hover {
    color: #ffb300;
}

.popular-post span {
    color: #666666;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
}

.polular-post-slider-active {
    padding-top: 10px;
}

.polular-post-slider-active .cr-navigation {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    height: 30px;
    width: 30px;
    display: inline-block;
    border-radius: 0;
    background: #f7f7f7;
    border: 1px solid #f7f7f7;
    font-size: 18px;
    text-align: center;
    padding: 1px 0;
    color: #adadad;
    z-index: 2;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    visibility: hidden;
    opacity: 0;
}

.polular-post-slider-active .cr-navigation:hover {
    background: #ffb300;
    border-color: #ffb300;
    color: #ffffff;
}

.polular-post-slider-active .cr-navigation.cr-navigation-prev {
    left: 0;
    right: auto;
}

.polular-post-slider-active .cr-navigation.cr-navigation-next {
    left: auto;
    right: 0;
}

.polular-post-slider-active:hover .cr-navigation {
    opacity: 1;
    visibility: visible;
}


/**
* Widget Styles
*/

.widgets:not(.footer-widgets) {
    overflow: hidden;
}

.widgets:not(.footer-widgets) .widget-title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 13px;
    margin-bottom: 20px;
}

.widgets:not(.footer-widgets) .widget-title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 48px;
    background: #868686;
}

.widgets:not(.footer-widgets) .single-widget {
    margin-bottom: 50px;
}

.widgets:not(.footer-widgets) .single-widget ul {
    padding-left: 0;
    margin-bottom: 0;
}

.widgets:not(.footer-widgets) .single-widget ul li {
    list-style: none;
}

.widgets:not(.footer-widgets) .single-widget:last-child {
    margin-bottom: 0;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul {
    background: #f7f7f7;
    padding: 20px;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li {
    position: relative;
    padding-left: 22px;
    font-size: 13px;
    line-height: 22px;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li+li {
    margin-top: 23px;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li:before {
    content: "\f099";
    font-family: 'fontAwesome';
    position: absolute;
    left: 0;
    top: 0;
    color: #ffb300;
    font-size: 16px;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li p {
    margin-bottom: 0;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li .tweet {
    font-size: 13px;
    line-height: 22px;
    color: #666666;
    margin-bottom: 5px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li .tweet a {
    color: #ffb300;
    display: inline-block;
    padding-right: 4px;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li .tweet a:hover {
    opacity: 0.7;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li .timePosted {
    font-weight: 700;
    font-size: 13px;
    line-height: 26px;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li .timePosted a {
    color: #003366;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li .timePosted a:hover {
    color: #ffb300;
}

.widgets:not(.footer-widgets) .widget-twitter-feed ul li .interact {
    display: none;
}

.widgets:not(.footer-widgets) .widget-popular-post ul {
    margin-bottom: 0;
    padding-left: 0;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li {
    list-style: none;
    position: relative;
    min-height: 58px;
    border-top: 1px solid #f5f5f5;
    padding: 20px 0;
    padding-left: 100px;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li .widget-popular-thumb {
    position: absolute;
    display: inline-block;
    width: 85px;
    left: 0;
    top: 20px;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li .widget-popular-thumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 179, 0, 0.7);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li .widget-popular-thumb::after {
    content: "\f0c1";
    font-family: 'fontAwesome';
    position: absolute;
    left: 50%;
    top: 50%;
    color: #ffffff;
    font-size: 16px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin-top: 15px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li h6 {
    margin-top: -2px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #2b2b35;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li h6 a {
    color: #2b2b35;
    display: block;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li h6 a:hover {
    color: #ffb300;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li span {
    color: #666666;
    font-style: italic;
    font-size: 11px;
    display: inline-block;
    line-height: 1.1;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li:first-child {
    border-top: none;
    padding-top: 0;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li:first-child .widget-popular-thumb {
    top: 0;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li:last-child {
    padding-bottom: 0;
}

.widgets:not(.footer-widgets) .widget-popular-post ul li:hover .widget-popular-thumb::before,
.widgets:not(.footer-widgets) .widget-popular-post ul li:hover .widget-popular-thumb::after {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
}

.widgets:not(.footer-widgets) .widget-categories ul li a {
    display: block;
    background: #f7f7f7;
    font-size: 14px;
    color: #666666;
    padding: 15px 0;
    padding-left: 25px;
    padding-right: 10px;
    border-top: 1px solid #ebebeb;
}

.widgets:not(.footer-widgets) .widget-categories ul li a:hover {
    color: #ffb300;
}

.widgets:not(.footer-widgets) .widget-categories ul li:first-child a {
    border-top: none;
}

.widgets:not(.footer-widgets) .widget-archives .widget-archives-inner {
    padding: 20px;
    background: #f7f7f7;
}

.widgets:not(.footer-widgets) .widget-archives .nice-select {
    display: block;
    width: 100%;
    border-radius: 0px;
    border: none;
    padding: 0;
    float: none;
}

.widgets:not(.footer-widgets) .widget-archives .nice-select::after {
    display: none;
}

.widgets:not(.footer-widgets) .widget-archives .nice-select span.current {
    display: block;
    position: relative;
    color: #666666;
    letter-spacing: 1.5px;
    font-size: 12px;
    padding: 0 20px;
}

.widgets:not(.footer-widgets) .widget-archives .nice-select span.current::after {
    content: "\f0d7";
    font-family: 'fontAwesome';
    position: absolute;
    left: auto;
    right: 0;
    bottom: 3px;
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: inline-block;
    font-size: 18px;
    padding: 0px 12px;
}

.widgets:not(.footer-widgets) .widget-archives .nice-select .list {
    width: 100%;
    border-radius: 0px;
    margin-top: -1px;
    max-height: 280px;
    overflow-y: auto;
}

.widgets:not(.footer-widgets) .widget-archives .nice-select.open span.current::after {
    content: "\f0d8";
}

.widgets:not(.footer-widgets) .widget-tags ul {
    background: #f7f7f7;
    padding: 10px 10px 20px 20px;
    font-size: 0;
}

.widgets:not(.footer-widgets) .widget-tags ul li {
    display: inline-block;
    margin-top: 10px;
    margin-right: 9px;
    font-size: 13px;
}

.widgets:not(.footer-widgets) .widget-tags ul li a {
    display: inline-block;
    font-size: 13px;
    color: #666666;
    background: #ffffff;
    padding: 5px 15px;
    border-radius: 3px;
}

.widgets:not(.footer-widgets) .widget-tags ul li a:hover {
    color: #ffffff;
    background: #ffb300;
}

.widgets:not(.footer-widgets) .widget-instafeed .widget-instafeed-inner {
    background: #f7f7f7;
    position: relative;
    margin-bottom: 0;
    padding-bottom: 25px;
}

.widgets:not(.footer-widgets) .widget-instafeed .widget-instafeed-inner .slick-dots {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    font-size: 0;
}

.widgets:not(.footer-widgets) .widget-instafeed .widget-instafeed-inner .slick-dots li {
    font-size: 12px;
    margin: 0 3px;
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 100px;
    background: #c2c2c2;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widgets:not(.footer-widgets) .widget-instafeed .widget-instafeed-inner .slick-dots li.slick-active,
.widgets:not(.footer-widgets) .widget-instafeed .widget-instafeed-inner .slick-dots li:hover {
    background: #141414;
}

.widgets:not(.footer-widgets) .widget-instafeed .widget-instafeed-inner .slick-dots li button {
    display: none;
}

.widgets:not(.footer-widgets) .widget-instafeed .sidebar-instafeed-single {
    text-align: center;
}

.widgets:not(.footer-widgets) .widget-instafeed .sidebar-instafeed-single span {
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    color: #333333;
    font-weight: 700;
    text-transform: uppercase;
    padding: 22px 15px;
    padding-bottom: 30px;
    display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .widgets:not(.footer-widgets) {
        margin-top: 80px;
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 30px;
        column-gap: 30px;
    }
    .widgets:not(.footer-widgets) .single-widget {
        margin-bottom: 30px;
        background: #f7f7f7;
        padding: 30px;
        width: 100%;
        -webkit-column-break-inside: avoid;
        break-inside: avoid-column;
    }
    .widgets:not(.footer-widgets) .widget-twitter-feed ul {
        padding: 0;
    }
    .widgets:not(.footer-widgets) .widget-categories ul li a {
        padding-left: 0;
    }
    .widgets:not(.footer-widgets) .widget-archives .widget-archives-inner {
        padding: 0;
    }
    .widgets:not(.footer-widgets) .widget-tags ul {
        padding: 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .widgets:not(.footer-widgets) {
        margin-top: 80px;
        -webkit-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 30px;
        column-gap: 30px;
    }
    .widgets:not(.footer-widgets) .single-widget {
        margin-bottom: 30px;
        background: #f7f7f7;
        padding: 30px;
        width: 100%;
        -webkit-column-break-inside: avoid;
        break-inside: avoid-column;
    }
    .widgets:not(.footer-widgets) .widget-twitter-feed ul {
        padding: 0;
    }
    .widgets:not(.footer-widgets) .widget-categories ul li a {
        padding-left: 0;
    }
    .widgets:not(.footer-widgets) .widget-archives .widget-archives-inner {
        padding: 0;
    }
    .widgets:not(.footer-widgets) .widget-tags ul {
        padding: 0;
    }
}

@media only screen and (max-width: 767px) {
    .widgets:not(.footer-widgets) {
        margin-top: 80px;
    }
}


/**
* Team Member
*/

.team-member .team-member-thumb {
    display: block;
    overflow: hidden;
    margin-bottom: 15px;
}

.team-member .team-member-thumb img {
    width: 100%;
    -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    -o-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}

.team-member h5 {
    color: #2b2b35;
    font-weight: 600;
    color: #444444;
    margin-bottom: 5px;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.team-member h6 {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: #666666;
    margin-bottom: 0;
}

.team-member p {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 14px;
    font-family: "Raleway", sans-serif;
}

.team-member .social-icons {
    padding: 18px 15px;
    background: #f5f5f5;
    margin-top: 20px;
}

.team-member .social-icons ul li {
    margin: 0px 12px;
}

.team-member .social-icons ul li a {
    color: #333333;
    font-size: 14px;
}

.team-member:hover h5 {
    color: #ffb300;
}

.team-member.team-member-2 {
    margin-bottom: 30px;
    position: relative;
}

.team-member.team-member-2 .team-member-thumb {
    position: relative;
    z-index: 1;
}

.team-member.team-member-2 .team-member-thumb img {
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
}

.team-member.team-member-2 .team-member-thumb::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.62);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.team-member.team-member-2 .team-member-content {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.team-member.team-member-2 .team-member-content h5 {
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 10px;
    text-transform: uppercase;
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.team-member.team-member-2 .team-member-content h6 {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #dddddd;
    margin-bottom: 0;
    text-transform: uppercase;
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0.1s;
    -o-transition: all 0.4s ease-in-out 0.1s;
    transition: all 0.4s ease-in-out 0.1s;
}

.team-member.team-member-2 .social-icons {
    position: absolute;
    bottom: 35px;
    background: transparent;
    padding: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.team-member.team-member-2 .social-icons ul {
    margin-bottom: 0;
}

.team-member.team-member-2 .social-icons ul li {
    margin: 0 7px;
}

.team-member.team-member-2 .social-icons ul li a {
    background: #ffffff;
    color: #2b2b35;
    height: 30px;
    width: 30px;
    line-height: 30px;
}

.team-member.team-member-2 .social-icons ul li a:hover {
    color: #ffffff;
    background: #ffb300;
}

.team-member.team-member-2:hover .team-member-thumb::before {
    visibility: visible;
    opacity: 1;
}

.team-member.team-member-2:hover .social-icons {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.team-member.team-member-2:hover .team-member-content h5,
.team-member.team-member-2:hover .team-member-content h6 {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
}


/**
* Funfact Area
*/

.funfact-wrap [class^="col"]:nth-child(even) .funfact {
    background: #e8e8e8;
}

.funfact-wrap [class^="col"]:nth-child(odd) .funfact {
    background: #f3f3f3;
}

.funfact {
    text-align: center;
    padding: 100px 30px;
}

.funfact .funfact-inner {
    text-align: left;
    display: inline-block;
    position: relative;
    padding-left: 70px;
}

.funfact .funfact-inner i {
    position: absolute;
    left: 0;
    top: 5px;
    width: 70px;
    font-size: 48px;
    color: #ffb300;
}

.funfact .funfact-inner span {
    color: #2b2b35;
    margin-bottom: 0;
    font-weight: 700;
    font-family: "Lato", sans-serif;
    font-size: 30px;
}

.funfact .funfact-inner p {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 0;
}

.funfact2 {
    padding: 25px 0;
}

.funfact2 .funfact2-icon {
    height: 100px;
    width: 100px;
    display: inline-block;
    background: #ffb300;
    border-radius: 1000px;
    text-align: center;
    line-height: 70px;
    padding: 15px;
    margin-bottom: 15px;
}

.funfact2 .odometer {
    display: block;
    font-size: 48px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: #ffffff;
    font-family: "Lato", sans-serif;
}

.funfact2 h6 {
    letter-spacing: 0.5px;
    font-weight: 700;
    color: #ffffff;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .funfact {
        padding: 50px 20px;
    }
    .funfact .funfact-inner {
        padding-left: 60px;
    }
    .funfact .funfact-inner i {
        width: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact-wrap [class^="col"]:nth-child(even) .funfact {
        border-top: 1px solid #f3f3f3;
    }
    .funfact-wrap [class^="col"]:nth-child(odd) .funfact {
        border-top: 1px solid #e8e8e8;
    }
}

@media only screen and (max-width: 767px) {
    .funfact-wrap [class^="col"]:nth-child(even) .funfact {
        border-top: 1px solid #f3f3f3;
    }
    .funfact-wrap [class^="col"]:nth-child(odd) .funfact {
        border-top: 1px solid #e8e8e8;
    }
    .funfact {
        padding: 50px 20px;
    }
    .funfact2 .odometer {
        font-size: 32px;
    }
}


/**
* Testimonial Styles
*/

.testimonial-area {
    background-image: url(images/bg/testimonial-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.testimonial {
    position: relative;
    padding: 0 90px;
}

.testimonial::before {
    content: "";
    height: 52px;
    width: 52px;
    display: inline-block;
    background-image: url(images/icons/testimonial-quote.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-bottom: 25px;
}

.testimonial p {
    font-size: 16px;
    font-style: italic;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 30px;
    color: #2b2b35;
}

.testimonial h6 {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    display: inline-block;
    padding-top: 25px;
}

.testimonial h6:after {
    content: "-";
    display: inline-block;
    margin: 0 10px;
}

.testimonial span {
    color: #2b2b35;
    display: inline-block;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    text-transform: initial;
    font-style: italic;
    font-weight: 400;
}

.testimonial-wrap {
    position: relative;
}

.testimonial-wrap.slick-dotted {
    padding-bottom: 60px;
    margin-bottom: 0;
}

.testimonial-wrap .cr-slider-dots {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-bottom: 0;
    padding-left: 0;
    bottom: 0;
    line-height: 1;
}

.testimonial-wrap .cr-slider-dots li {
    display: inline-block;
    margin: 0 7px;
    line-height: 0;
    height: 11px;
    width: 11px;
    background: transparent;
    border: 1px solid #838383;
    border-radius: 100px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
}

.testimonial-wrap .cr-slider-dots li:first-child {
    margin-left: 0;
}

.testimonial-wrap .cr-slider-dots li:last-child {
    margin-right: 0;
}

.testimonial-wrap .cr-slider-dots li button {
    display: none;
}

.testimonial-wrap .cr-slider-dots li.slick-active,
.testimonial-wrap .cr-slider-dots li:hover {
    background: #ffb300;
    border-color: #ffb300;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .testimonial {
        padding: 0 80px;
    }
    .testimonial-wrap.slick-dotted {
        padding-bottom: 40px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial {
        padding: 0 30px;
    }
    .testimonial-wrap.slick-dotted {
        padding-bottom: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial {
        padding: 0;
    }
    .testimonial h6 {
        padding-top: 10px;
    }
    .testimonial-wrap.slick-dotted {
        padding-bottom: 40px;
    }
}


/* Testimonial Style 2 */

.testimonial2 .testimonial2-text {
    background: #f7f7f7;
    padding: 30px 27px;
    font-family: "Lato", sans-serif;
    font-style: italic;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    margin-bottom: 25px;
}

.testimonial2 .testimonial2-text::after {
    content: "";
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    border-width: 11px 10px 6px 17px;
    border-style: solid;
    border-color: #d6d6d6 #d6d6d6 transparent transparent;
}

.testimonial2 .testimonial2-text p {
    margin-bottom: 0;
}

.testimonial2 .testimonial2-text p::before {
    content: "\"";
}

.testimonial2 .testimonial2-author {
    margin-left: 27px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.testimonial2 .testimonial2-author-image {
    height: 90px;
    width: 90px;
    display: inline-block;
    border-radius: 1000px;
    overflow: hidden;
    margin-right: 15px;
}

.testimonial2 .testimonial2-author-image img {
    max-height: 100%;
}

.testimonial2 .testimonial2-author-content {
    -ms-flex-negative: 100;
    flex-shrink: 100;
}

.testimonial2 .testimonial2-author-content h5 {
    margin-bottom: 3px;
    color: #444444;
    font-weight: 600;
}

.testimonial2 .testimonial2-author-content h6 {
    margin-bottom: 0;
    font-size: 13px;
    color: #666666;
}

.testimonial2-wrapper.slick-dotted {
    margin-bottom: 0;
}

.testimonial2-wrapper .cr-slider-dots {
    margin-top: 20px;
    font-size: 0;
    padding-left: 0;
    margin-bottom: 0;
}

.testimonial2-wrapper .cr-slider-dots li {
    font-size: 12px;
    margin: 0 3px;
    height: 12px;
    width: 12px;
    display: inline-block;
    border-radius: 100px;
    background: transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border: 2px solid #dbdbdb;
    cursor: pointer;
}

.testimonial2-wrapper .cr-slider-dots li.slick-active,
.testimonial2-wrapper .cr-slider-dots li:hover {
    background: #dbdbdb;
}

.testimonial2-wrapper .cr-slider-dots li button {
    display: none;
}


/**
* Pricebox Styles
*/

.pricebox {
    background: #ffffff;
    padding-bottom: 50px;
    border-radius: 5px;
    margin-top: 30px;
}

.pricebox .pricebox-title {
    color: #ffffff;
    font-weight: 800;
    background: #323334;
    text-transform: uppercase;
    padding: 21px 25px;
    margin-bottom: 0;
    border-radius: 4px 4px 0 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.pricebox .pricebox-price {
    font-size: 48px;
    font-weight: 900;
    font-family: "Lato", sans-serif;
    color: #2b2b35;
    margin-bottom: 0;
    padding: 43px 0;
    display: inline-block;
    line-height: 42px;
}

.pricebox .pricebox-price-currency {
    font-size: 18px;
    font-weight: 900;
    vertical-align: text-top;
    padding-right: 3px;
}

.pricebox .pricebox-price-timeout {
    font-size: 12px;
    font-weight: 400;
    font-weight: 900;
}

.pricebox .pricebox-price-timeout::before {
    content: "/";
    padding-right: 10px;
}

.pricebox ul {
    padding: 0 25px;
    padding-bottom: 50px;
    margin-bottom: 0;
}

.pricebox ul li {
    padding: 13px 0;
    list-style: none;
    border-bottom: 1px solid #f5f5f5;
}

.pricebox ul li:first-child {
    border-top: 1px solid #f5f5f5;
}

.pricebox a.cr-btn {
    padding: 13px 20px;
    font-size: 12px;
}

.pricebox.is-active .pricebox-title,
.pricebox:hover .pricebox-title {
    background: #ffb300;
}

.pricebox.is-active a.cr-btn,
.pricebox:hover a.cr-btn {
    background: #ffb300;
    color: #ffffff;
    border-color: #ffb300;
}

.pricebox.is-active a.cr-btn:hover,
.pricebox:hover a.cr-btn:hover {
    background: #ffb300;
    color: #ffffff;
    border-color: #ffb300;
}


/**
* Contact Styles
*/

.contact-infobox {
    margin-top: 30px;
}

.contact-infobox span.contact-infobox-icon {
    display: inline-block;
    height: 95px;
    width: 95px;
    border-radius: 1000px;
    background: #323435;
    color: #ffffff;
    text-align: center;
    font-size: 36px;
    padding: 30px 0;
    margin-bottom: 27px;
    position: relative;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.contact-infobox span.contact-infobox-icon::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 1000px;
    border: 2px solid #ffb300;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.contact-infobox h6 {
    font-weight: 700;
    color: #323435;
    text-transform: uppercase;
    margin-bottom: 25px;
}

.contact-infobox p {
    margin-bottom: 0;
}

.contact-infobox a {
    color: #666666;
}

.contact-infobox a:hover {
    color: #ffb300;
}

.contact-infobox:hover span.contact-infobox-icon {
    background: #ffb300;
}

.contact-infobox:hover span.contact-infobox-icon::before {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.contact-page-form input {
    height: 55px;
}

.contact-page-form input,
.contact-page-form select,
.contact-page-form textarea {
    color: #555555;
    font-size: 14px;
    border: 1px solid #e8e8e8;
    background: #e8e8e8;
    padding: 20px;
}

.contact-page-form input::-webkit-input-placeholder,
.contact-page-form select::-webkit-input-placeholder,
.contact-page-form textarea::-webkit-input-placeholder {
    color: #555555;
}

.contact-page-form input:-moz-placeholder,
.contact-page-form select:-moz-placeholder,
.contact-page-form textarea:-moz-placeholder {
    color: #555555;
}

.contact-page-form input::-moz-placeholder,
.contact-page-form select::-moz-placeholder,
.contact-page-form textarea::-moz-placeholder {
    color: #555555;
}

.contact-page-form input:-ms-input-placeholder,
.contact-page-form select:-ms-input-placeholder,
.contact-page-form textarea:-ms-input-placeholder {
    color: #555555;
}

.contact-page-form input:focus,
.contact-page-form select:focus,
.contact-page-form textarea:focus {
    background: transparent;
}

@media only screen and (max-width: 767px) {
    .contact-page-form {
        margin-top: -30px;
    }
}


/**
* Pg Blog Item Styles
*/

.pg-blog-item {
    margin-bottom: 50px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ebebeb;
}

.pg-blog-item .pg-blog-item-category {
    color: #ffb300;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 12px;
    font-size: 12px;
}

.pg-blog-item .pg-blog-item-category a {
    color: #ffb300;
}

.pg-blog-item .pg-blog-item-category a:hover {
    color: #2b2b35;
}

.pg-blog-item .pg-blog-item-title {
    text-transform: uppercase;
    color: #424242;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 1px;
}

.pg-blog-item .pg-blog-item-title a {
    color: #2b2b35;
}

.pg-blog-item .pg-blog-item-title a:hover {
    color: #ffb300;
}

.pg-blog-item .pg-blog-item-meta {
    font-size: 14px;
    color: #666666;
    margin-bottom: 12px;
}

.pg-blog-item .pg-blog-item-meta span {
    display: inline-block;
    margin-bottom: 10px;
}

.pg-blog-item .pg-blog-item-meta span::after {
    content: "|";
    padding: 0 5px;
}

.pg-blog-item .pg-blog-item-meta span:last-child::after {
    display: none;
}

.pg-blog-item .pg-blog-item-meta a {
    color: #666666;
}

.pg-blog-item .pg-blog-item-meta a:hover {
    color: #ffb300;
}

.pg-blog-item .pg-blog-item-image {
    margin-bottom: 22px;
}

.pg-blog-item .pg-blog-item-image img {
    width: 100%;
}

.pg-blog-item p {
    text-align: left;
    font-size: 14px;
}

.pg-blog-item p .mark {
    color: #ffb300;
    background: transparent;
}

.pg-blog-item h5 {
    text-align: left;
    font-family: "Lato", sans-serif;
    font-weight: 700;
}

.pg-blog-item blockquote {
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
}

.pg-blog-item .cr-loadmore-btn {
    margin-top: 20px;
}

.pg-blog-item .pg-blog-item-footer {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pg-blog-item .pg-blog-item-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
    padding-right: 30px;
}

.pg-blog-item .pg-blog-item-share span {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    padding-right: 12px;
    color: #666666;
}

.pg-blog-item .pg-blog-item-share span:after {
    content: "|";
    padding-left: 12px;
}

.pg-blog-item .pg-blog-item-comments {
    margin-top: 20px;
    color: #666666;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.pg-blog-item .pg-blog-item-comments i {
    font-size: 14px;
    color: #2b2b35;
    padding-right: 10px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.pg-blog-item .pg-blog-item-comments span:before {
    content: "|";
    padding: 0 5px;
}

.pg-blog-item .pg-blog-item-comments:hover {
    color: #ffb300;
}

.pg-blog-item .pg-blog-item-comments:hover i {
    color: #ffb300;
}

.pg-blog-item .pg-blog-item-tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 20px;
    padding-right: 30px;
}

.pg-blog-item .pg-blog-item-tags span {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    padding-right: 12px;
    color: #666666;
}

.pg-blog-item .pg-blog-item-tags span:after {
    content: "|";
    padding-left: 12px;
}

.pg-blog-item .pg-blog-item-tags ul {
    padding-left: 0;
    margin-bottom: 0;
}

.pg-blog-item .pg-blog-item-tags ul li {
    list-style: none;
    display: inline-block;
    margin: 0 4px;
}

.pg-blog-item .pg-blog-item-tags ul li:first-child {
    margin-left: 0;
}

.pg-blog-item .pg-blog-item-tags ul li:last-child {
    margin-right: 0;
}

.pg-blog-item .pg-blog-item-tags ul li a {
    display: inline-block;
    color: #666666;
    padding: 6px 15px;
    background: #f7f7f7;
    font-size: 13px;
    border-radius: 3px;
}

.pg-blog-item .pg-blog-item-tags ul li a:hover {
    background: #ffb300;
    color: #ffffff;
}

.blog-page-area .cr-pagination {
    padding-top: 33px;
    position: relative;
}

.blog-page-area .cr-pagination::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 1px;
    width: 100%;
    background: #ebebeb;
}

.blog-page-area .cr-pagination::after {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    display: block;
    height: 1px;
    width: 100%;
    background: #ebebeb;
}

.blog-details p {
    font-size: 14px;
}

.blog-details .pg-blog-item-footer {
    margin-top: 45px;
}

.blog-details .pg-blog-item-tags {
    padding-right: 20px;
}

.blog-details .pg-blog-item-comments {
    margin-right: 20px;
}

.blog-details .pg-blog-item-share {
    padding-right: 0;
}

.blog-details-author {
    position: relative;
    padding-left: 150px;
    margin-top: 30px;
    min-height: 125px;
}

.blog-details-author img {
    position: absolute;
    left: 0;
    top: 0;
    width: 125px;
}

.blog-details-author h6 {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.blog-details-author p {
    font-size: 14px;
}

.blog-details-popular-post {
    margin-top: 70px;
}

.blog-details-popular-post>h5 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    padding-left: 20px;
}

.blog-details-popular-post>h5::before {
    content: "";
    position: absolute;
    height: 12px;
    width: 12px;
    background: #ffb300;
    left: 0;
    top: 3px;
}

.blog-details-commentlist {
    margin-top: 60px;
}

.blog-details-commentlist>h5 {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    display: block;
    padding-bottom: 15px;
    text-transform: uppercase;
}

.blog-details-commentlist>h5::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    background: #e1e1e1;
    width: 100%;
}

.blog-details-commentbox {
    margin-top: 70px;
}

.blog-details-commentbox h5 {
    font-weight: 700;
    font-size: 16px;
    display: block;
    text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
    .blog-details-author {
        position: relative;
        padding-left: 0;
        margin-top: 30px;
        min-height: 125px;
    }
    .blog-details-author img {
        position: relative;
        width: 75px;
        margin-bottom: 20px;
    }
    .pg-blog-item .pg-blog-item-title {
        font-size: 16px;
    }
}


/**
* Project Details
*/

.project-details-info {
    margin-top: 70px;
}

.project-details-info ul {
    margin-bottom: 0;
    padding-left: 0;
}

.project-details-info ul li {
    list-style: none;
    margin-bottom: 10px;
    font-size: 16px;
}

.project-details-info ul li:last-child {
    margin-bottom: 0;
}

.project-details-info ul li span {
    font-weight: 900;
}

.project-details-description {
    margin-top: 70px;
}

.project-details-description p {
    font-size: 16px;
    margin-bottom: 30px;
}

.project-details-description p:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
    .project-details-info,
    .project-details-description {
        margin-top: 50px;
    }
}


/**
* 404 Page Styles
*/

.pg-404-content {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pg-404-content img {
    max-width: 100%;
}

.pg-404-content h5 {
    font-weight: 800;
    letter-spacing: -0.3px;
    line-height: 1.8;
}

.pg-404-content p {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-top: 25px;
}

.pg-404-content form {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
}

.pg-404-content form input {
    border-radius: 3px;
    height: 52px;
    line-height: 46px;
    border: 2px solid #a1a1a1;
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    margin-top: 30px;
    margin-right: 10px;
    -ms-flex-negative: 100;
    flex-shrink: 100;
}

.pg-404-content form input::-webkit-input-placeholder {
    color: #666666;
}

.pg-404-content form input:-moz-placeholder {
    color: #666666;
}

.pg-404-content form input::-moz-placeholder {
    color: #666666;
}

.pg-404-content form input:-ms-input-placeholder {
    color: #666666;
}

.pg-404-content form input:focus {
    border-color: #ffb300;
}

.pg-404-content form button {
    margin-top: 30px;
    display: inline-block;
    background: #ffb300;
    font-size: 14px;
    border-width: 1px;
    border-color: #ffb300;
    border-style: solid;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    overflow: hidden;
    position: relative;
    line-height: 1.3;
    padding: 15px 60px;
    vertical-align: middle;
    border-radius: 3px;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
}

.pg-404-content form button:hover {
    background: #ffb300;
    border-color: #ffb300;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .pg-404-content img {
        max-width: 50%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .pg-404-content img {
        max-width: 50%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .pg-404-image {
        text-align: center;
    }
    .pg-404-content {
        padding-bottom: 0;
    }
    .pg-404-content img {
        max-width: 50%;
    }
}

@media only screen and (max-width: 767px) {
    .pg-404-image {
        text-align: center;
    }
    .pg-404-content {
        padding-bottom: 0;
    }
    .pg-404-content h3 {
        letter-spacing: 1px;
    }
    .pg-404-content p {
        margin-top: 0;
        margin-bottom: 0;
    }
    .pg-404-content img {
        max-width: 60%;
    }
}

@media only screen and (max-width: 575px) {
    .pg-404-content {
        padding-bottom: 0;
    }
    .pg-404-content h3 {
        letter-spacing: 0;
    }
    .pg-404-content img {
        max-width: 90%;
    }
    .pg-404-content form {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .pg-404-content form input {
        margin-right: 0;
        width: 100%;
    }
}


/**
* Comment Styles
*/


/* Commentlist */

.single-comment {
    position: relative;
    padding-left: 75px;
    min-height: 60px;
    margin-top: 40px;
}

.single-comment img {
    width: 60px;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 0;
}

.single-comment h6 {
    font-size: 12px;
    font-weight: 700;
    color: #2b2b35;
    margin-bottom: 0;
}

.single-comment span {
    font-size: 12px;
    color: #666666;
    font-family: "Lato", sans-serif;
    font-style: italic;
}

.single-comment p {
    font-size: 14px;
    margin-bottom: 0;
}

.single-comment .reply-button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #323334;
    background: #ebebeb;
    padding: 0px 13px;
    border-radius: 3px;
}

.single-comment .reply-button:hover {
    background: #ffb300;
    color: #ffffff;
}

.single-comment.reply-comment {
    margin-left: 70px;
}

@media only screen and (max-width: 575px) {
    .single-comment {
        padding-left: 0;
    }
    .single-comment img {
        position: relative;
        margin-bottom: 20px;
    }
    .single-comment.reply-comment {
        margin-left: 40px;
    }
}


/* Commentbox */

.commentbox form input {
    height: 42px;
}

.commentbox form input+input {
    margin-top: 20px;
}

.commentbox form input,
.commentbox form select,
.commentbox form textarea {
    color: #666666;
    font-size: 13px;
    border: 1px solid #cfcfcf;
    background: transparent;
    padding: 20px;
}

.commentbox form input::-webkit-input-placeholder,
.commentbox form select::-webkit-input-placeholder,
.commentbox form textarea::-webkit-input-placeholder {
    color: #666666;
}

.commentbox form input:-moz-placeholder,
.commentbox form select:-moz-placeholder,
.commentbox form textarea:-moz-placeholder {
    color: #666666;
}

.commentbox form input::-moz-placeholder,
.commentbox form select::-moz-placeholder,
.commentbox form textarea::-moz-placeholder {
    color: #666666;
}

.commentbox form input:-ms-input-placeholder,
.commentbox form select:-ms-input-placeholder,
.commentbox form textarea:-ms-input-placeholder {
    color: #666666;
}

.commentbox form input:focus,
.commentbox form select:focus,
.commentbox form textarea:focus {
    background: transparent;
    border-color: #cfcfcf;
}

.commentbox form textarea {
    padding: 10px 20px;
    min-height: 166px;
}

.commentbox form button,
.commentbox form input[type="submit"] {
    display: inline-block;
    background: #323435;
    font-size: 12px;
    border-width: 1px;
    border-color: #323435;
    border-style: solid;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    overflow: hidden;
    position: relative;
    line-height: 1.3;
    padding: 14px 32px;
    vertical-align: middle;
    border-radius: 3px;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 20px;
    height: 42px;
}

.commentbox form button:hover,
.commentbox form input[type="submit"]:hover {
    background: #ffb300;
    color: #ffffff;
    border-color: #ffb300;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .commentbox form textarea {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .commentbox form textarea {
        margin-top: 20px;
    }
}


/**
* Slider Elements
*/

.slider-navigation-style-1 {
    position: relative;
}

.slider-navigation-style-1 .cr-navigation {
    position: absolute;
    display: inline-block;
    height: 45px;
    width: 25px;
    text-align: center;
    top: -110px;
    left: auto;
    font-size: 24px;
    line-height: 41px;
    color: #6b6b6b;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    z-index: 2;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    background: #ffffff;
    cursor: pointer;
}

.slider-navigation-style-1 .cr-navigation:hover {
    background: #ffb300;
    color: #ffffff;
    border-color: #ffb300;
}

.slider-navigation-style-1 .cr-navigation.cr-navigation-prev {
    right: 50px;
}

.slider-navigation-style-1 .cr-navigation.cr-navigation-prev:before {
    right: 100%;
    left: auto;
}

.slider-navigation-style-1 .cr-navigation.cr-navigation-prev:after {
    left: 100%;
    right: auto;
}

.slider-navigation-style-1 .cr-navigation.cr-navigation-next {
    right: 15px;
}

@media only screen and (max-width: 767px) {
    .slider-navigation-style-1 .cr-navigation {
        top: -95px;
    }
}

@media only screen and (max-width: 575px) {
    .slider-navigation-style-1 {
        padding-bottom: 80px;
    }
    .slider-navigation-style-1 .cr-navigation {
        top: auto;
        bottom: 0;
    }
    .slider-navigation-style-1 .cr-navigation.cr-navigation-prev {
        left: auto;
        right: calc(50% + 5px);
    }
    .slider-navigation-style-1 .cr-navigation.cr-navigation-next {
        left: calc(50% + 5px);
        right: auto;
    }
}

.slider-dots-style-1 {
    padding-bottom: 75px;
    margin-bottom: 0 !important;
    position: relative;
}

.slider-dots-style-1 .slick-dots {
    position: absolute;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    bottom: 0;
    left: 0;
}

.slider-dots-style-1 .slick-dots li {
    width: 12px;
    height: 12px;
    display: inline-block;
    background: #ffffff;
    border-radius: 100px;
    margin: 0 5px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.slider-dots-style-1 .slick-dots li button {
    display: none;
}

.slider-dots-style-1 .slick-dots li:first-child {
    margin-left: 0;
}

.slider-dots-style-1 .slick-dots li:last-child {
    margin-right: 0;
}

.slider-dots-style-1 .slick-dots li.slick-active {
    background: #ffb300;
}

@media only screen and (max-width: 767px) {
    .slider-dots-style-1 {
        padding-bottom: 50px;
    }
}


/**
Footer Styles
*/

.footer {
    position: relative;
    z-index: 1;
}

.footer-top-area {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 25px;
}

.footer-social-icons {
    margin-top: 25px;
    padding-left: 0;
    margin-bottom: 0;
    font-size: 0;
}

.footer-social-icons li {
    list-style: none;
    display: inline-block;
    font-size: 18px;
    margin: 0 4px;
}

.footer-social-icons li:first-child {
    margin-left: 0;
}

.footer-social-icons li:last-child {
    margin-right: 0;
}

.footer-social-icons li a {
    height: 45px;
    width: 45px;
    display: inline-block;
    line-height: 43px;
    font-size: 18px;
    color: #cccccc;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 100px;
    text-align: center;
}

.footer-social-icons li:hover a {
    background: #ffb300;
    border-color: #ffb300;
    color: #ffffff;
}

.footer-newsletter-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 25px;
}

.footer-newsletter-form h4 {
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-right: 34px;
}

.footer-newsletter-form input[type="email"] {
    border: 2px solid #ffb300;
    color: #ffffff;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-style: italic;
    font-family: "Lato", sans-serif;
    border-radius: 3px 0 0 3px;
    height: 45px;
    line-height: 41px;
}

.footer-newsletter-form input[type="email"]:focus {
    border-color: #ffb300;
}

.footer-newsletter-form input[type="email"]::-webkit-input-placeholder {
    color: #ffffff;
}

.footer-newsletter-form input[type="email"]:-moz-placeholder {
    color: #ffffff;
}

.footer-newsletter-form input[type="email"]::-moz-placeholder {
    color: #ffffff;
}

.footer-newsletter-form input[type="email"]:-ms-input-placeholder {
    color: #ffffff;
}

.footer-newsletter-form input[type="submit"],
.footer-newsletter-form button {
    background: #ffb300;
    border: 1px solid #ffb300;
    display: inline-block;
    font-size: 14px;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
    line-height: 1.3;
    padding: 13px 32px;
    vertical-align: middle;
    border-radius: 0 3px 3px 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    -ms-flex-negative: 100;
    flex-shrink: 100;
    -webkit-transition: all 0.4s ease-out 0s;
    -o-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
}

.footer-newsletter-form input[type="submit"]:hover,
.footer-newsletter-form button:hover {
    background: rgba(255, 179, 0, 0.9);
    border-color: #ffb300;
}


/* Footoer Widgets */

.widgets.footer-widgets {
    /* Widget About */
    /* Widget Contact */
    /** Widget Quick Links */
    /* Instafeed */
}

.widgets.footer-widgets .single-widget ul {
    padding-left: 0;
    margin-bottom: 0;
}

.widgets.footer-widgets .single-widget ul li {
    list-style: none;
}

.widgets.footer-widgets .widget-title {
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    position: relative;
    padding-bottom: 8px;
}

.widgets.footer-widgets .widget-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 38px;
    background: #ffb300;
}

.widgets.footer-widgets .widget-about>a {
    display: inline-block;
    margin-bottom: 28px;
}

.widgets.footer-widgets .widget-about p {
    color: #ffffff;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 0;
}

.widgets.footer-widgets .widget-contact ul li {
    color: #000;
    font-size: 13px;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.widgets.footer-widgets .widget-contact ul li a {
    color: #ffffff;
}

.widgets.footer-widgets .widget-contact ul li a:hover {
    color: #ffb300;
}

.widgets.footer-widgets .widget-contact ul li+li {
    margin-top: 20px;
}

.widgets.footer-widgets .widget-contact ul li p {
    line-height: 20px;
    margin-bottom: 0;
    -ms-flex-negative: 100;
    flex-shrink: 100;
}

.widgets.footer-widgets .widget-contact ul li span {
    font-size: 14px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 3px;
    height: 32px;
    width: 32px;
    display: inline-block;
    line-height: 28px;
    text-align: center;
    margin-right: 20px;
}

.widgets.footer-widgets .widget-contact ul li.phone,
.widgets.footer-widgets .widget-contact ul li.fax {
    font-size: 15px;
}

.widgets.footer-widgets .widget-quick-links ul {
    margin-top: -4px;
}

.widgets.footer-widgets .widget-quick-links ul li {
    display: block;
}

.widgets.footer-widgets .widget-quick-links ul li a {
    display: block;
    font-size: 13px;
    color: #ffffff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 9px 0;
}

.widgets.footer-widgets .widget-quick-links ul li a:hover {
    color: #ffb300;
}

.widgets.footer-widgets .widget-quick-links ul li:first-child a {
    padding-top: 0;
}

.widgets.footer-widgets .widget-quick-links ul li:last-child a {
    border-bottom: none;
    padding-bottom: 0;
}

.widgets.footer-widgets .widget-instafeed .widget-instafeed-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: -12px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.widgets.footer-widgets .widget-instafeed .widget-instafeed-footer .sidebar-instafeed-single {
    margin-top: 12px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    position: relative;
}

.widgets.footer-widgets .widget-instafeed .widget-instafeed-footer .sidebar-instafeed-single a {
    display: inline-block;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.widgets.footer-widgets .widget-instafeed .widget-instafeed-footer .sidebar-instafeed-single a::after {
    content: "\f16d";
    font-family: 'fontAwesome';
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 18px;
    color: #ffffff;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0;
    margin-top: 20px;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.widgets.footer-widgets .widget-instafeed .widget-instafeed-footer .sidebar-instafeed-single a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 179, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -o-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.widgets.footer-widgets .widget-instafeed .widget-instafeed-footer .sidebar-instafeed-single a:hover::before {
    opacity: 1;
    visibility: visible;
}

.widgets.footer-widgets .widget-instafeed .widget-instafeed-footer .sidebar-instafeed-single a:hover::after {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.footer-copyright-area {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-copyright {
    padding: 22px 0;
    font-size: 13px;
    color: #ffffff;
}

.footer-copyright p {
    margin-bottom: 0;
}

.footer-copyright a {
    color: #ffffff;
}

.footer-copyright a:hover {
    color: #ffb300;
}

.copyright-area-social {
    padding: 10px 0;
}

.copyright-payment-method-image {
    text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .widgets.footer-widgets [class^="col"]+[class^="col"] .single-widget {
        margin-top: 50px;
    }
    .widgets.footer-widgets .widget-instafeed .widget-instafeed-footer .sidebar-instafeed-single {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 15%;
        flex: 0 0 15%;
    }
}

@media only screen and (max-width: 767px) {
    .footer-social-icons li a {
        height: 38px;
        width: 38px;
        line-height: 38px;
        font-size: 14px;
    }
    .widgets.footer-widgets [class^="col"]+[class^="col"] .single-widget {
        margin-top: 50px;
    }
    .copyright-area-social {
        padding: 0;
        padding-bottom: 30px;
    }
    .footer-copyright {
        text-align: center;
        padding: 18px 0;
    }
    .copyright-payment-method-image {
        text-align: center;
        padding-bottom: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .footer-newsletter-form {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .footer-newsletter-form h4 {
        margin-bottom: 15px;
    }
    .footer-newsletter-form input[type="email"] {
        margin-right: 0;
    }
    .footer-newsletter-form input[type="submit"],
    .footer-newsletter-form button {
        margin-top: 20px;
    }
}


/**
 * Boxed Layout Styles
 */

.boxed-layout {
    width: calc(100% - 300px);
    margin: 0 auto;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    position: relative;
}

.boxed-layout .sticky-header.is-sticky .header-bottom-area {
    width: calc(100% - 300px);
    margin-left: 150px;
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
    .boxed-layout {
        width: calc(100% - 180px);
    }
    .boxed-layout .sticky-header.is-sticky .header-bottom-area {
        width: calc(100% - 180px);
        margin-left: 90px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .boxed-layout {
        width: calc(100% - 100px);
    }
    .boxed-layout .sticky-header.is-sticky .header-bottom-area {
        width: calc(100% - 100px);
        margin-left: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .boxed-layout {
        width: calc(100% - 60px);
    }
    .boxed-layout .sticky-header.is-sticky .header-bottom-area {
        width: calc(100% - 60px);
        margin-left: 30px;
    }
    .boxed-layout .banner-slider-active .cr-navigation {
        height: 45px;
        width: 45px;
        border-width: 2px;
        font-size: 22px;
        padding: 8px 0;
        top: auto;
        bottom: 30px;
    }
    .boxed-layout .banner-slider-active:hover .cr-navigation.cr-navigation-prev {
        left: auto;
        right: calc( 50% + 12px);
    }
    .boxed-layout .banner-slider-active:hover .cr-navigation.cr-navigation-next {
        right: auto;
        left: calc( 50% + 12px);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .boxed-layout {
        width: calc(100% - 30px);
    }
    .boxed-layout .sticky-header.is-sticky .header-bottom-area {
        width: calc(100% - 30px);
        margin-left: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .boxed-layout {
        width: 100%;
    }
    .boxed-layout .sticky-header.is-sticky .header-bottom-area {
        width: 100%;
        margin-left: 0;
    }
}


/*# sourceMappingURL=style.css.map */