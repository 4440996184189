/*********************************************************************************

Template Name: Salina - Construction Bootstrap4 Template 
Template URI: https: //themeforest.net/user/
Description: A perfect construction template for build beautiful and unique construction websites. It comes with nice and clean design. 
Author: Manuel Molina
Author URI: Author URI 
Version: 1.0

Note: This is custom css. All custom styles (user styles) declare here.

**********************************************************************************/

.container-fluid {
    margin: 0%;
    padding: 0%;
}

.header-top-area-inner li {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    background-color: #2B2B35;
    padding: 20px;
    color: white;
}

header>a:nth-child(2) {
    justify-content: left;
}

.paneles-aislados {
    /* width: 100%; */
    height: 100%;
    width: 50%;
}

/* .main-image {
    max-height: 150px;
} */

.navbarTop {
    border-top: 1px solid rgb(65, 65, 77);
    background: #2B2B35;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 999;
    position: fixed;
    top: 50px;
    width: 100%;
    height: 150px;
    transition: top .1s;
    padding-left: 50px;
    padding-right: 50px;
    -webkit-box-shadow: 0px 5px 4px -5px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 5px 4px -5px rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 4px -5px rgba(255, 255, 255, 1);
}

.about-timeline-content ul {
    padding: 0;
}

.navbarTop>a:nth-child(2) {
    margin-left: auto;
}

.navbarTop button {
    border-color: transparent;
    border-radius: 5px;
    height: 30px;
    padding-left: 5px;
    padding-right: 5px;
    float: left;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 17px;
    font-weight: bold;
}

.navbarTop button:hover {
    background-color: #F1B22E;
    color: black;
}


.single-project {
    -webkit-box-shadow: 0px 0px 18px -10px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 18px -10px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 18px -10px rgba(255, 255, 255, 1);
}




/* 
dropdown
*/

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    border-radius: 10px;
    background-color: #f1f1f1;
    min-width: 160px;
    top: 30px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: .9s;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}


/* 
text
*/

.about-area-images {
    margin-bottom: 50px;
}

.text-image-row {
    margin-bottom: 50px;
}


/* misc */

.cr-breadcrumb h1 {
    color: white;
}


/* 
products section
*/

.product-area {
    padding-left: 5%;
    padding-right: 5%;
}

.product-flex {
    display: flex;
    flex-flow: row wrap;
    margin: 50px;
}

.flex-product-column {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
}

.flex-product-row {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 30px;
    border-bottom: 1px solid rgb(175, 175, 175);
}


/* image */

.product-image {
    width: 300px;
    height: 300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: .3s;
}

.product-image:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.product-image img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}


/* 
main page image
*/


/* info */

.product-sumary {
    margin-left: 30px;
}

.product-sumary p {
    text-decoration: underline;
    font-style: italic;
    margin: 0 0 5px;
}

.product-sumary h5 {
    margin: 0 0 5px;
}


/* datasheet */

.card {
    margin-left: auto;
    width: 120px;
    height: 130px;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    cursor: pointer;
}

.card img {
    margin: 10px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
    padding: 2px 16px;
}


/* Manuals */

.manuals-area {
    margin: 100px;
}

.manuals-area .row {
    margin-bottom: 100px;
}

.manuals-area .card {
    margin: 0;
    width: 190px;
    height: 200px;
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    cursor: pointer;
}

.card-body {
    margin: 0;
}

.manuals-area .card img {
    margin: 10px;
}

.manuals-area .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.manuals-area h5 {
    margin-top: 20px;
}


/* product specs */

.product-specs {
    margin-top: 30px;
}


/* contact */

.contact-flex {
    align-self: flex-start;
    margin-left: auto;
    margin-right: auto;
}

.form-area {
    padding: 20px;
    background-color: rgb(83, 83, 85);
    border-radius: 10px;
}


/* table */

td,
th {
    border: 1px solid #dddddd;
    text-align: center;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #dddddd;
}


/* 
map
*

/* chat */

.chat-container {
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 340px;
    height: auto;
    position: fixed;
    bottom: 0;
    right: 15px;
    z-index: 999;
}

.chat-button {
    width: 100%;
    margin: 0;
    cursor: pointer;
    user-select: none;
    padding: 4px 0;
    background-color: #337AB7;
    text-align: center;
    color: #fff;
}