.carousel {
  top:150px;
  .carousel-item {
    height: 500px !important;
    img {
      min-height: 100%;
      min-width: 100%;
    }
    .carousel-caption {
      margin-bottom: 150px;
      h1 {
        font-weight: bolder;
        font-size: 4vw;
        color: white;
        text-transform: uppercase;
      }
      h3 {
        font-size: 2vw;
        color: white;
      }
    }
  }
}

@media (max-width: 900px) {
  .carousel-item {
    height: 500px !important;
    img {
      min-height: 100%;
      min-width: 100%;
    }
    .carousel-caption {
      margin-bottom: 150px;
      h1 {
        font-weight: bolder;
        font-size: 5vw;
        color: white;
        text-transform: uppercase;
      }
      h3 {
        font-size: 3vw;
        color: white;
      }
    }
  }
}

@media (max-width: 500px) {
  .carousel-item {
    height: 70vh !important;
    img {
      min-height: 100%;
      min-width: 100%;
    }
    .carousel-caption {
      margin-bottom: 150px;
      h1 {
        font-weight: bolder;
        font-size: 6vw;
        color: white;
        text-transform: uppercase;
      }
      h3 {
        font-size: 4vw;
        color: white;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .carousel {
    top: 100px;
  }
}
