@media screen and (max-width: 1180px) {
  header {
    display: none !important;
  }
}

header {
  top          : 0px !important;
  padding      : 10px !important;
  display      : block;
  position     : absolute;
  width        : 100%;
  display      : flex;
  align-items  : center;
  border-bottom: 1px solid rgba(126, 126, 126, 0.281);
}

@media screen and (min-width: 1329px) and (max-width: 5000px) {
  .header-top-area-inner {
    ul {
      li {
        font-weight: bolder;
        font-size  : 17px;
      }
    }
  }
}